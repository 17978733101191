<template>
  <div class="container" v-click-outside="close">
    <custom-button variant="secondary" @click="toggle">
      {{ label }}
      <svg
        :class="{ active: show }"
        class="arrow"
        width="2154"
        height="1211"
        viewBox="0 0 2154 1211"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M97.365 4.73113C71.2451 13.1569 56.5 22.004 37.542 41.8046C11.4221 68.7671 0.468568 95.3083 0.468568 133.646C0.468568 196.418 -34.9197 156.817 509.386 701.122C1053.69 1245.43 1014.09 1210.04 1076.86 1210.04C1139.63 1210.04 1100.03 1245.43 1644.34 701.122C2189.07 156.817 2153.26 196.418 2153.26 133.646C2152.83 110.053 2150.73 96.5722 2143.57 80.5632C2131.35 54.022 2101.02 23.2679 2073.21 10.6292L2052.15 0.939533L1083.18 0.0969547C310.116 -0.324334 110.846 0.518244 97.365 4.73113Z"
        />
      </svg>
    </custom-button>
    <div :class="show ? 'collapse' : 'collapse hide'">
      <span class="tittle" v-if="$slots.content">
        <slot name="tittle"></slot>
      </span>
      <span class="content" v-if="$slots.content">
        <slot name="content"></slot>
      </span>
      <span class="tittle" v-if="$slots.content2">
        <slot name="tittle2"></slot>
      </span>
      <span class="content" v-if="$slots.content2">
        <slot name="content2"></slot>
      </span>
      <span class="tittle" v-if="$slots.content2">
        <slot name="tittle3"></slot>
      </span>
      <span class="content" v-if="$slots.content2">
        <slot name="content3"></slot>
      </span>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import CustomButton from "../CustomButton.vue";
export default {
  name: "Collapse",
  components: {
    CustomButton
  },
  data() {
    return {
      show: false
    };
  },
  props: {
    label: String
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {},

  methods: {
    toggle() {
      this.show = !this.show;
    },
    close() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
}

.collapse {
  position: absolute;
  top: calc(100% + 1em);
  border-radius: 5px;
  background-color: white;
  width: 20em;
  z-index: 1;
  border: solid 1px rgba(128, 128, 128, 0.26);
  height: 17.4em;
  overflow-y: scroll;
  box-shadow: 0px 2px 6px -4px rgba(138, 121, 121, 0.75);
}

.collapse span {
  width: 100%;
}

.hide {
  display: none;
}

.content {
  padding: 1em;
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
}

.tittle {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 0.5em 0.5em 0.5em 1em;
  margin-bottom: 1em;
  width: 100%;
  height: max-content;
  background-color: var(--mainColor1);
}

.tittle * {
  color: white;
  font-weight: var(--weight-bold);
}

.arrow {
  height: fit-content;
  max-height: 0.7em;
  width: 0.7em;
  fill: var(--fontColor3);
  transition: transform 0.3s;
}

.active.arrow {
  transform: rotate(180deg);
}
</style>
