<template>
  <div
    class="select-container"
    :class="active ? 'active' : ''"
    v-click-outside="close"
  >
    <div class="label" @click="active = !active">
      <h2>{{ value }}</h2>
      <svg
        width="2154"
        height="1211"
        viewBox="0 0 2154 1211"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M97.365 4.73113C71.2451 13.1569 56.5 22.004 37.542 41.8046C11.4221 68.7671 0.468568 95.3083 0.468568 133.646C0.468568 196.418 -34.9197 156.817 509.386 701.122C1053.69 1245.43 1014.09 1210.04 1076.86 1210.04C1139.63 1210.04 1100.03 1245.43 1644.34 701.122C2189.07 156.817 2153.26 196.418 2153.26 133.646C2152.83 110.053 2150.73 96.5722 2143.57 80.5632C2131.35 54.022 2101.02 23.2679 2073.21 10.6292L2052.15 0.939533L1083.18 0.0969547C310.116 -0.324334 110.846 0.518244 97.365 4.73113Z"
        />
      </svg>
    </div>
    <div :class="active ? 'panel active' : 'panel'">
      <div v-for="(option, index) in options" :key="index" class="option">
        <h4
          @click="handleOptionClick(option)"
          :class="selected === option ? 'active' : ''"
        >
          {{ option }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  name: "CustomSelect",
  props: {
    value: String,
    options: {
      type: Array,
      required: true
    }
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    return {
      active: false,
      selected: ""
    };
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    handleOptionClick(option) {
      this.active = false;
      this.selected = option;
      this.$emit("input", option);
    },
    close() {
      this.active = false;
    }
  }
};
</script>

<style scoped>
h2 {
  font-size: 1.2em;
}

h4 {
  font-size: 1.05em;
  font-weight: 500;
  cursor: pointer;
  color: #abacb2;
  padding: 0.3em 1.5em 0.3em 0.7em;
  margin: 0.1em 0;
  border-radius: 6px;
}

h4:hover {
  color: var(--fontColor1);
}

h4.active {
  background-color: #f5f4f9;
  color: var(--fontColor1);
}

.select-container {
  position: relative;
  font-size: 14px;
}

.label:hover h2,
.label:hover svg,
.active .label * {
  color: var(--mainColor1);
  fill: var(--mainColor1);
}

.label {
  display: flex;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  gap: 0.9em;
  cursor: pointer;
}

.panel {
  top: 115%;
  width: fit-content;
  z-index: 9;
  background: white;
  position: absolute;
  overflow: hidden;
  user-select: none;
  max-height: 0;
  box-shadow: 0px 2px 6px -4px rgba(138, 121, 121, 0.75);
}

.panel.active {
  padding: 0.2em 0em;
  overflow-y: auto;
  max-height: fit-content;
  border: solid 1px #d1d1d1;
  border-radius: 5px;
}

.panel div:hover {
  font-weight: var(--weight-semi-bold);
}

svg {
  height: fit-content;
  max-height: 0.7em;
  width: 0.7em;
  fill: var(--fontColor3);
  transition: transform 0.3s;
}

.option {
  margin: 0.5em;
}

.active svg {
  transform: rotate(180deg);
}

.option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-height: 1000px) {
  .select-container {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .select-container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .select-container {
    font-size: 14px;
  }
}

@media (max-height: 550px) {
  .select-container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .select-container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .select-container {
    font-size: 8px;
  }
}
</style>
