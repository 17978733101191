<template>
  <custom-button
    variant="secondary"
    class="main-chart-period"
    @click="active = !active"
    v-click-outside="close"
    :class="{ active: active }"
  >
    <font-awesome-icon :icon="icons.calendar" slot="icon" />
    {{ selectedSurveyName }}
    <svg
      class="arrow"
      width="2154"
      height="1211"
      viewBox="0 0 2154 1211"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.365 4.73113C71.2451 13.1569 56.5 22.004 37.542 41.8046C11.4221 68.7671 0.468568 95.3083 0.468568 133.646C0.468568 196.418 -34.9197 156.817 509.386 701.122C1053.69 1245.43 1014.09 1210.04 1076.86 1210.04C1139.63 1210.04 1100.03 1245.43 1644.34 701.122C2189.07 156.817 2153.26 196.418 2153.26 133.646C2152.83 110.053 2150.73 96.5722 2143.57 80.5632C2131.35 54.022 2101.02 23.2679 2073.21 10.6292L2052.15 0.939533L1083.18 0.0969547C310.116 -0.324334 110.846 0.518244 97.365 4.73113Z"
      />
    </svg>
    <div class="panel">
      <div v-for="(survey, index) in surveys" :key="index" class="option">
        <p
          :class="{ active: selectedSurveyName === survey.name }"
          @click="$emit('input', survey)"
        >
          {{ survey.name }}
        </p>
      </div>
    </div>
  </custom-button>
</template>

<script>
import vClickOutside from "v-click-outside";
import CustomButton from "../CustomButton.vue";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "PeriodSelect",
  components: {
    CustomButton
  },
  data() {
    return {
      active: false,
      icons: {
        calendar: faCalendarAlt
      }
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    surveys: {
      type: Array
    },
    selectedSurveyName: {
      type: String
    }
  },

  methods: {
    close() {
      this.active = false;
    }
  },
  computed: {},
  mounted() {}
};
</script>

<style scoped>
.main-chart-period {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  padding: 0.75rem var(--lengthSm3);
  font-size: var(--lengthSm3);
  font-weight: var(--medium);
  border-radius: 4px;
  color: var(--mainColor1);
  background-color: white;
  box-shadow: 0 0 1px 1px var(--grayColor2) inset;
  position: relative;
  z-index: 1000;
}

p {
  font-size: 0.95em;
  font-weight: 500;
  cursor: pointer;
  color: #abacb2;
  padding: 0.3em 1.5em 0.3em 0.7em;
  margin: 0.1em 0;
  border-radius: 6px;
  text-align: left;
}

p:hover {
  color: var(--fontColor1);
}

p.active {
  background-color: #f5f4f9;
  color: var(--fontColor1);
}

.panel {
  top: 115%;
  min-width: 100%;
  width: fit-content;
  z-index: 9;
  background: white;
  position: absolute;
  overflow: hidden;
  user-select: none;
  max-height: 0;
  box-shadow: 0px 2px 6px -4px rgba(138, 121, 121, 0.75);
}

.active .panel {
  padding: 0.2em 0em;
  overflow-y: auto;
  max-height: fit-content;
  border: solid 1px #d1d1d1;
  border-radius: 5px;
}

.panel div:hover {
  font-weight: var(--weight-semi-bold);
}

.option {
  margin: 0.5em;
}

.option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow {
  height: fit-content;
  max-height: 0.7em;
  width: 0.7em;
  fill: var(--fontColor3);
  transition: transform 0.3s;
}

.active .arrow {
  transform: rotate(180deg);
}
</style>
