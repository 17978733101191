<template>
  <div class="container">
    <div class="charts">
      <line-chart
        v-if="chartType === 'line'"
        :chartData="chartData"
        :colors="colors"
      />
      <clock-chart
        v-else-if="chartType == 'clockBar'"
        :chartData="chartData"
        :colors="colors"
        :showXAxis="true"
      />
      <bar-chart
        v-else
        :chartData="chartData"
        :colors="colors"
        :showXAxis="true"
        :isShapeCircle="isShapeCircle"
      />
    </div>

    <modal :isOpen="isReportModalOpen" @close="$emit('close', false)">
      <div class="file-buttons">
        <custom-button
          variant="button"
          download="reporte.csv"
          :href="downloadLink"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button variant="button" @click="downloadAsPDF">
          PDF
          <font-awesome-icon :icon="icons.pdf" slot="icon" />
        </custom-button>
      </div>

      <div ref="printMe">
        <template slot="title">Reporte</template>
        <div class="report-title">
          <div class="logo-container">
            <svg
              id="Capa_1"
              data-name="Capa 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1161.25 526.9"
              ref="logo"
            >
              <path
                class="cls-1"
                fill="#0054B4"
                d="M360.28,389H177.52q-.28,7.59-.28,13.22,0,30.36,16.17,45.83a54,54,0,0,0,38.66,15.46q41.9,0,50.33-44.7l75.91,5.62q-31.77,98.4-129,98.41-42.46,0-72.12-17t-48.92-49.63Q89,423.63,89,378.08q0-67.77,38.66-109.37T227,227.09q54.83,0,94,38.66T360.28,389Zm-183-48.64H278.18q-3.94-58.48-48.08-58.48Q182.87,281.92,177.24,340.4Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-1"
                fill="#0054B4"
                d="M413.14,517V133.19h83.78V517Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-1"
                fill="#0054B4"
                d="M647.06,133.19V271.61q23.06-44.54,76.76-44.52,28.68,0,49.06,12.52t28.68,32.19q8.3,19.68,8.3,57.64V517H726.07V356.78q0-28.71-2.25-39.41a32.4,32.4,0,0,0-10.22-17.88q-8-7.17-20-7.17-15.71,0-31.09,12.26t-15.42,52.2V517H565.81V133.19Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-1"
                fill="#0054B4"
                d="M1126.72,517h-81.25q-3.95-16.87-3.94-44.42Q1020.2,502.91,999,512.89t-48.15,10q-39.86,0-64.44-21.65T861.87,443a89.9,89.9,0,0,1,14.32-49.21q14.29-22.49,46-35.28t115.65-19V323.25q0-41.33-41.22-41.33-44,0-49,41.33l-77.32-7.31q7.61-48.36,45.83-68.6t86.6-20.25a213.14,213.14,0,0,1,54.55,6.75q25.58,6.75,39.92,20.24t19.4,28.54q5.07,15,5.06,52.44V463.83Q1121.66,500.94,1126.72,517Zm-88.84-136.08q-87.72,8.72-87.73,53.14a38.19,38.19,0,0,0,9.26,25.72q9.24,10.83,26.34,10.83,22.14,0,37.13-18.42t15-45.4Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-2"
                fill="#3d3f3e"
                d="M1243.45,503.2c-16.1,8.77-27.74,15.5-39.74,21.52-7.68,3.85-8.67,7.41-2.18,13.55,10.68,10.09,20.52,21.07,31.38,31,7.13,6.49,9.66,11.21.78,18.59-10.8,9-19.77,26-30.88,27.21-11.7,1.28-20.36-17-30.48-26.61-5.69-5.38-10-12.22-16.63-20.54l-29,51.31L1085.6,462.28Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M316.94,622.81v21.9h-14v-59h23.24q7.77,0,11.9,1.66a17.57,17.57,0,0,1,7.36,5.94,16.88,16.88,0,0,1,3.24,10.5,21.84,21.84,0,0,1-1.73,8.51,15.3,15.3,0,0,1-4.88,6.52,17.94,17.94,0,0,1-6.11,3.15,39.73,39.73,0,0,1-9.27.78Zm-.48-10.71h9c3.42,0,5.83-.8,7.21-2.38a8,8,0,0,0,2.07-5.44,7.35,7.35,0,0,0-2.31-5.7c-1.54-1.42-3.88-2.12-7-2.12h-8.94Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M355.68,644.71v-59h12.88v59Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M417.2,644.71H404.71a31.3,31.3,0,0,1-.6-6.82q-3.28,4.67-6.54,6.19a17,17,0,0,1-7.39,1.54,14.39,14.39,0,0,1-9.9-3.33,11.32,11.32,0,0,1-3.78-8.94,13.84,13.84,0,0,1,2.2-7.56,14.47,14.47,0,0,1,7.08-5.42q4.86-2,17.77-2.92V615q0-6.36-6.34-6.35c-4.5,0-7,2.11-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,417.2,644.71ZM403.55,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4.05,1.66,7.07,7.07,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M441.56,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17c1.18,0,2.54-.06,4.09-.18v9.55a55.92,55.92,0,0,1-8.08.95q-8,0-11.4-3.09t-3.37-11.16V611.1h-6.91v-10h7.64l1-12.87,11.1-.87v13.74h9.37v10Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M497,644.71H484.5a31.22,31.22,0,0,1-.61-6.82q-3.27,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.53,6.35l-11.88-1.13q1.16-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.07,30.07,0,0,0,497,644.71ZM483.33,623.8q-13.47,1.35-13.48,8.17a5.89,5.89,0,0,0,1.43,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M520.27,611.1v33.61H507.74V611.1h-5.06v-10h5.06a41.24,41.24,0,0,1,1-9.83,10.08,10.08,0,0,1,4.56-5.48q3.68-2.4,10-2.4a43,43,0,0,1,6,.48v9.93c-1.85-.14-3.37-.22-4.58-.22-1.76,0-2.92.47-3.5,1.39a8.77,8.77,0,0,0-.86,4.49v1.64h7.9v10Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M552.6,600.17q10,0,15.56,6.53T573.74,623q0,9.68-5.8,16.13t-15.43,6.46q-9.32,0-14.91-6.4T532,622.81A25.43,25.43,0,0,1,534.54,611a20.93,20.93,0,0,1,6.93-7.84A19.46,19.46,0,0,1,552.6,600.17Zm.35,8.43a6.15,6.15,0,0,0-5.52,2.83q-1.84,2.82-1.85,11.25c0,5.15.59,8.86,1.76,11.1a5.83,5.83,0,0,0,5.52,3.37,6.22,6.22,0,0,0,5.3-2.87q2-2.88,2-10.95,0-8.6-1.78-11.67A5.9,5.9,0,0,0,553,608.6Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M594.35,644.71H581.87V601.08h11.79v8.12q2.72-9.08,9.42-9.07c.63,0,1.41,0,2.33.13V613a12.67,12.67,0,0,0-6.11,1.21,8.73,8.73,0,0,0-3.57,4.34,21.32,21.32,0,0,0-1.38,8.45Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M674.91,644.71H662V620.56a44.41,44.41,0,0,0-.3-6.63,4.24,4.24,0,0,0-1.58-2.55,5.54,5.54,0,0,0-3.52-1,7.62,7.62,0,0,0-4.32,1.51q-2.21,1.51-2.2,8.68v24.15H637.25V620.56a28.89,28.89,0,0,0-.48-6.76,4.7,4.7,0,0,0-1.79-2.48,5.62,5.62,0,0,0-3.35-1,5.88,5.88,0,0,0-5,2.09q-1.62,2.1-1.62,8.1v24.15H612.58V601.08h12.09v6.65q3.08-7.56,12.29-7.56,8.35,0,12.07,7.56,3.63-7.56,12.66-7.56a14.77,14.77,0,0,1,7.43,1.77,10.42,10.42,0,0,1,4.45,4.63c.9,1.9,1.34,5.17,1.34,9.8Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M723.55,644.71H711.07a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.54,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.84,13.84,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.77,0-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.49,30.49,0,0,0,723.55,644.71ZM709.9,623.8q-13.47,1.35-13.47,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M756.12,585.75h18q11,0,16.4,3a28.51,28.51,0,0,1,9.67,9.63q4.27,6.6,4.27,16.66,0,12.64-7.46,21.14t-20.59,8.49H756.12Zm13.57,10.84v37h5.69q7.54,0,11.21-5.64a25.24,25.24,0,0,0,1.57-23.15,13.84,13.84,0,0,0-5.19-6.4,14.45,14.45,0,0,0-7.46-1.83Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M825.76,584.54v12.87H812.89V584.54Zm0,16.54v43.63H812.89V601.08Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M879.93,602.07a17.44,17.44,0,0,0-3.41-.43q-4.28,0-5,3.8a11.74,11.74,0,0,1,3.49,8.3,11.5,11.5,0,0,1-5.16,9.85q-5.16,3.66-14.88,3.67a34.16,34.16,0,0,1-6.74-.66,3.83,3.83,0,0,0-2.2,3.34,2.89,2.89,0,0,0,1.86,3c1.23.49,4.22.85,8.94,1.08a117,117,0,0,1,13.86,1.25,11.45,11.45,0,0,1,6.4,3.54,9.92,9.92,0,0,1,2.59,7q0,6-5.55,10.15t-19.68,4.15q-13.26,0-17.88-2.87c-3.08-1.93-4.62-4.35-4.62-7.3q0-5.82,7.47-7.87-5.53-2.76-5.53-7.77,0-6.27,8.73-9.76c-5.16-2.14-7.74-5.68-7.74-10.67a11.6,11.6,0,0,1,5.34-9.87q5.34-3.81,14.75-3.81a35.6,35.6,0,0,1,11.53,1.9,12.1,12.1,0,0,1,2.19-6.11q1.79-2.4,7.62-2.4a18.47,18.47,0,0,1,3.63.35Zm-33.65,42.34a3.36,3.36,0,0,0-2,1,3.24,3.24,0,0,0-1.19,2.51c0,2,1.26,3.18,3.76,3.67a46.5,46.5,0,0,0,8.82.74q6.6,0,9.26-1c1.77-.66,2.66-1.72,2.66-3.19,0-1.13-.74-2-2.23-2.6s-5.43-.9-11.85-.9l-2.6,0a24.19,24.19,0,0,1-3.19-.13A8.81,8.81,0,0,0,846.28,644.41Zm8.57-37.28a7.22,7.22,0,0,0-5.19,1.79,6.24,6.24,0,0,0-1.87,4.73q0,6.66,7.11,6.65a7.32,7.32,0,0,0,5.4-1.94,6.39,6.39,0,0,0,2-4.71,6.46,6.46,0,0,0-1.85-4.56A7.3,7.3,0,0,0,854.85,607.13Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M898.16,584.54v12.87H885.29V584.54Zm0,16.54v43.63H885.29V601.08Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M923.26,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17q1.77,0,4.08-.18v9.55a55.67,55.67,0,0,1-8.07.95q-8,0-11.41-3.09t-3.37-11.16V611.1h-6.91v-10h7.65l1-12.87,11.11-.87v13.74h9.37v10Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M978.68,644.71H966.19a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.31,11.31,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.4,14.4,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.54,6.35l-11.88-1.13q1.17-7.43,7-10.54a28.08,28.08,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.68,10.68,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,978.68,644.71ZM965,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.05,7.05,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M988.53,644.71v-59h12.87v59Z"
                transform="translate(-88.96 -133.19)"
              />
            </svg>
          </div>
          <h1>Reporte Clima Laboral</h1>
        </div>

        <div class="report-filters">
          <h1>Filtros seleccionados:</h1>
          <div>
            <template v-for="key in Object.keys(filters)"
              >/
              <p :key="key + 'p'">{{ " " + key + ":" }}</p>
              <div :key="key + 'div'">
                <p v-for="(filter, index) in filters[key]" :key="filter">
                  {{ filter + (index == filters[key].length - 1 ? "" : ",") }}
                </p>
              </div>
            </template>
            <template v-if="Object.keys(selectedDimensions).length">
              /
              <p>{{ " " + "Dimension" + ":" }}</p>
              <div>
                <p
                  v-for="(key, index) in Object.keys(selectedDimensions)"
                  :key="key"
                >
                  {{
                    selectedDimensions[key] +
                      (index == Object.keys(selectedDimensions).length - 1
                        ? ""
                        : ",")
                  }}
                </p>
              </div>
              /
            </template>
          </div>
        </div>

        <div class="report-data-table">
          <div class="header row">
            <template>
              <span><p>Participación #</p></span>
              <span><p>Participación %</p></span>
              <span><p>Resultado General</p></span>
              <span><p>Dimensiones</p></span>
              <span><p>Preguntas</p></span>
            </template>
          </div>

          <div class="row">
            <span
              ><p>{{ participation[1] }}</p></span
            >
            <span
              ><p>{{ participation[0] }}%</p></span
            >
            <span
              :class="
                satisfactionIndex[0] <= limits[0]
                  ? 'limit-bad'
                  : satisfactionIndex[0] <= limits[1]
                  ? 'limit-medium'
                  : 'limit-good'
              "
            >
              <p>{{ satisfactionIndex[0] }}%</p>
            </span>
            <span>
              <p>{{ survey.sections.length }}</p>
            </span>
            <span>
              <p>{{ survey.questions.length }}</p>
            </span>
          </div>
          <div><span></span></div>
        </div>

        <div class="report-table" :style="tableLengthStyle">
          <div class="header row">
            <template v-if="selectedDisplay === 'Análisis de Resultados'">
              <span>{{ selectedCategory }}</span>
              <span><p>Fortaleza</p></span>
              <span><p>Oportunidad</p></span>
            </template>
            <template v-else>
              <span>{{ selectedCategory }}</span>
              <span
                v-for="scale in survey.scale.map(scale => scale.text)"
                :key="scale"
              >
                <p>{{ scale }}</p></span
              >
            </template>
          </div>
          <div
            class="row"
            v-for="category in Object.keys(chartData.dataset).sort((a, b) =>
              parseInt(chartData.dataset[a][0]) >
              parseInt(chartData.dataset[b][0])
                ? -1
                : parseInt(chartData.dataset[b][0]) >
                  parseInt(chartData.dataset[a][0])
                ? 1
                : 0
            )"
            :key="category"
          >
            <span>
              <p>{{ category }}</p>
            </span>
            <span
              v-for="(option, index) in chartData.dataset[category]"
              :key="option + category + index"
              :class="
                chartData.legend[index] === 'Fortaleza'
                  ? option <= limits[0]
                    ? 'limit-bad'
                    : option <= limits[1]
                    ? 'limit-medium'
                    : 'limit-good'
                  : ''
              "
              >{{ option }}%</span
            >
          </div>
          <div><span></span></div>
        </div>
      </div>
    </modal>

    <modal :isOpen="isOpenAnswersModalOpen" @close="$emit('close', false)">
      <div class="file-buttons">
        <custom-button
          variant="button"
          download="respuestas_abiertas.csv"
          :href="downloadLinkOA"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button variant="button" @click="downloadAsPDFOA">
          PDF
          <font-awesome-icon :icon="icons.pdf" slot="icon" />
        </custom-button>
      </div>

      <div>
        <template slot="title">Respuestas abiertas</template>
        <div class="openAnswers-title">
          <div class="logo-container">
            <svg
              id="Capa_1"
              data-name="Capa 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1161.25 526.9"
              ref="logo"
            >
              <path
                class="cls-1"
                fill="#0054B4"
                d="M360.28,389H177.52q-.28,7.59-.28,13.22,0,30.36,16.17,45.83a54,54,0,0,0,38.66,15.46q41.9,0,50.33-44.7l75.91,5.62q-31.77,98.4-129,98.41-42.46,0-72.12-17t-48.92-49.63Q89,423.63,89,378.08q0-67.77,38.66-109.37T227,227.09q54.83,0,94,38.66T360.28,389Zm-183-48.64H278.18q-3.94-58.48-48.08-58.48Q182.87,281.92,177.24,340.4Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-1"
                fill="#0054B4"
                d="M413.14,517V133.19h83.78V517Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-1"
                fill="#0054B4"
                d="M647.06,133.19V271.61q23.06-44.54,76.76-44.52,28.68,0,49.06,12.52t28.68,32.19q8.3,19.68,8.3,57.64V517H726.07V356.78q0-28.71-2.25-39.41a32.4,32.4,0,0,0-10.22-17.88q-8-7.17-20-7.17-15.71,0-31.09,12.26t-15.42,52.2V517H565.81V133.19Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-1"
                fill="#0054B4"
                d="M1126.72,517h-81.25q-3.95-16.87-3.94-44.42Q1020.2,502.91,999,512.89t-48.15,10q-39.86,0-64.44-21.65T861.87,443a89.9,89.9,0,0,1,14.32-49.21q14.29-22.49,46-35.28t115.65-19V323.25q0-41.33-41.22-41.33-44,0-49,41.33l-77.32-7.31q7.61-48.36,45.83-68.6t86.6-20.25a213.14,213.14,0,0,1,54.55,6.75q25.58,6.75,39.92,20.24t19.4,28.54q5.07,15,5.06,52.44V463.83Q1121.66,500.94,1126.72,517Zm-88.84-136.08q-87.72,8.72-87.73,53.14a38.19,38.19,0,0,0,9.26,25.72q9.24,10.83,26.34,10.83,22.14,0,37.13-18.42t15-45.4Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-2"
                fill="#3d3f3e"
                d="M1243.45,503.2c-16.1,8.77-27.74,15.5-39.74,21.52-7.68,3.85-8.67,7.41-2.18,13.55,10.68,10.09,20.52,21.07,31.38,31,7.13,6.49,9.66,11.21.78,18.59-10.8,9-19.77,26-30.88,27.21-11.7,1.28-20.36-17-30.48-26.61-5.69-5.38-10-12.22-16.63-20.54l-29,51.31L1085.6,462.28Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M316.94,622.81v21.9h-14v-59h23.24q7.77,0,11.9,1.66a17.57,17.57,0,0,1,7.36,5.94,16.88,16.88,0,0,1,3.24,10.5,21.84,21.84,0,0,1-1.73,8.51,15.3,15.3,0,0,1-4.88,6.52,17.94,17.94,0,0,1-6.11,3.15,39.73,39.73,0,0,1-9.27.78Zm-.48-10.71h9c3.42,0,5.83-.8,7.21-2.38a8,8,0,0,0,2.07-5.44,7.35,7.35,0,0,0-2.31-5.7c-1.54-1.42-3.88-2.12-7-2.12h-8.94Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M355.68,644.71v-59h12.88v59Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M417.2,644.71H404.71a31.3,31.3,0,0,1-.6-6.82q-3.28,4.67-6.54,6.19a17,17,0,0,1-7.39,1.54,14.39,14.39,0,0,1-9.9-3.33,11.32,11.32,0,0,1-3.78-8.94,13.84,13.84,0,0,1,2.2-7.56,14.47,14.47,0,0,1,7.08-5.42q4.86-2,17.77-2.92V615q0-6.36-6.34-6.35c-4.5,0-7,2.11-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,417.2,644.71ZM403.55,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4.05,1.66,7.07,7.07,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M441.56,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17c1.18,0,2.54-.06,4.09-.18v9.55a55.92,55.92,0,0,1-8.08.95q-8,0-11.4-3.09t-3.37-11.16V611.1h-6.91v-10h7.64l1-12.87,11.1-.87v13.74h9.37v10Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M497,644.71H484.5a31.22,31.22,0,0,1-.61-6.82q-3.27,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.53,6.35l-11.88-1.13q1.16-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.07,30.07,0,0,0,497,644.71ZM483.33,623.8q-13.47,1.35-13.48,8.17a5.89,5.89,0,0,0,1.43,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M520.27,611.1v33.61H507.74V611.1h-5.06v-10h5.06a41.24,41.24,0,0,1,1-9.83,10.08,10.08,0,0,1,4.56-5.48q3.68-2.4,10-2.4a43,43,0,0,1,6,.48v9.93c-1.85-.14-3.37-.22-4.58-.22-1.76,0-2.92.47-3.5,1.39a8.77,8.77,0,0,0-.86,4.49v1.64h7.9v10Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M552.6,600.17q10,0,15.56,6.53T573.74,623q0,9.68-5.8,16.13t-15.43,6.46q-9.32,0-14.91-6.4T532,622.81A25.43,25.43,0,0,1,534.54,611a20.93,20.93,0,0,1,6.93-7.84A19.46,19.46,0,0,1,552.6,600.17Zm.35,8.43a6.15,6.15,0,0,0-5.52,2.83q-1.84,2.82-1.85,11.25c0,5.15.59,8.86,1.76,11.1a5.83,5.83,0,0,0,5.52,3.37,6.22,6.22,0,0,0,5.3-2.87q2-2.88,2-10.95,0-8.6-1.78-11.67A5.9,5.9,0,0,0,553,608.6Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M594.35,644.71H581.87V601.08h11.79v8.12q2.72-9.08,9.42-9.07c.63,0,1.41,0,2.33.13V613a12.67,12.67,0,0,0-6.11,1.21,8.73,8.73,0,0,0-3.57,4.34,21.32,21.32,0,0,0-1.38,8.45Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M674.91,644.71H662V620.56a44.41,44.41,0,0,0-.3-6.63,4.24,4.24,0,0,0-1.58-2.55,5.54,5.54,0,0,0-3.52-1,7.62,7.62,0,0,0-4.32,1.51q-2.21,1.51-2.2,8.68v24.15H637.25V620.56a28.89,28.89,0,0,0-.48-6.76,4.7,4.7,0,0,0-1.79-2.48,5.62,5.62,0,0,0-3.35-1,5.88,5.88,0,0,0-5,2.09q-1.62,2.1-1.62,8.1v24.15H612.58V601.08h12.09v6.65q3.08-7.56,12.29-7.56,8.35,0,12.07,7.56,3.63-7.56,12.66-7.56a14.77,14.77,0,0,1,7.43,1.77,10.42,10.42,0,0,1,4.45,4.63c.9,1.9,1.34,5.17,1.34,9.8Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M723.55,644.71H711.07a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.54,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.84,13.84,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.77,0-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.49,30.49,0,0,0,723.55,644.71ZM709.9,623.8q-13.47,1.35-13.47,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M756.12,585.75h18q11,0,16.4,3a28.51,28.51,0,0,1,9.67,9.63q4.27,6.6,4.27,16.66,0,12.64-7.46,21.14t-20.59,8.49H756.12Zm13.57,10.84v37h5.69q7.54,0,11.21-5.64a25.24,25.24,0,0,0,1.57-23.15,13.84,13.84,0,0,0-5.19-6.4,14.45,14.45,0,0,0-7.46-1.83Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M825.76,584.54v12.87H812.89V584.54Zm0,16.54v43.63H812.89V601.08Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M879.93,602.07a17.44,17.44,0,0,0-3.41-.43q-4.28,0-5,3.8a11.74,11.74,0,0,1,3.49,8.3,11.5,11.5,0,0,1-5.16,9.85q-5.16,3.66-14.88,3.67a34.16,34.16,0,0,1-6.74-.66,3.83,3.83,0,0,0-2.2,3.34,2.89,2.89,0,0,0,1.86,3c1.23.49,4.22.85,8.94,1.08a117,117,0,0,1,13.86,1.25,11.45,11.45,0,0,1,6.4,3.54,9.92,9.92,0,0,1,2.59,7q0,6-5.55,10.15t-19.68,4.15q-13.26,0-17.88-2.87c-3.08-1.93-4.62-4.35-4.62-7.3q0-5.82,7.47-7.87-5.53-2.76-5.53-7.77,0-6.27,8.73-9.76c-5.16-2.14-7.74-5.68-7.74-10.67a11.6,11.6,0,0,1,5.34-9.87q5.34-3.81,14.75-3.81a35.6,35.6,0,0,1,11.53,1.9,12.1,12.1,0,0,1,2.19-6.11q1.79-2.4,7.62-2.4a18.47,18.47,0,0,1,3.63.35Zm-33.65,42.34a3.36,3.36,0,0,0-2,1,3.24,3.24,0,0,0-1.19,2.51c0,2,1.26,3.18,3.76,3.67a46.5,46.5,0,0,0,8.82.74q6.6,0,9.26-1c1.77-.66,2.66-1.72,2.66-3.19,0-1.13-.74-2-2.23-2.6s-5.43-.9-11.85-.9l-2.6,0a24.19,24.19,0,0,1-3.19-.13A8.81,8.81,0,0,0,846.28,644.41Zm8.57-37.28a7.22,7.22,0,0,0-5.19,1.79,6.24,6.24,0,0,0-1.87,4.73q0,6.66,7.11,6.65a7.32,7.32,0,0,0,5.4-1.94,6.39,6.39,0,0,0,2-4.71,6.46,6.46,0,0,0-1.85-4.56A7.3,7.3,0,0,0,854.85,607.13Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M898.16,584.54v12.87H885.29V584.54Zm0,16.54v43.63H885.29V601.08Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M923.26,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17q1.77,0,4.08-.18v9.55a55.67,55.67,0,0,1-8.07.95q-8,0-11.41-3.09t-3.37-11.16V611.1h-6.91v-10h7.65l1-12.87,11.11-.87v13.74h9.37v10Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M978.68,644.71H966.19a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.31,11.31,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.4,14.4,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.54,6.35l-11.88-1.13q1.17-7.43,7-10.54a28.08,28.08,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.68,10.68,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,978.68,644.71ZM965,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.05,7.05,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
                transform="translate(-88.96 -133.19)"
              />
              <path
                class="cls-3"
                fill="#3d3f3e"
                d="M988.53,644.71v-59h12.87v59Z"
                transform="translate(-88.96 -133.19)"
              />
            </svg>
          </div>
          <h1>{{ selectedCategory }}</h1>
        </div>
        <div class="report-filters">
          <h1>Filtros seleccionados:</h1>
          <div>
            <template v-for="key in Object.keys(filters)"
              >/
              <p :key="key + 'p'">{{ " " + key + ":" }}</p>
              <div :key="key + 'div'">
                <p v-for="(filter, index) in filters[key]" :key="filter">
                  {{ filter + (index == filters[key].length - 1 ? "" : ",") }}
                </p>
              </div>
            </template>
            <template v-if="Object.keys(selectedDimensions).length">
              /
              <p>{{ " " + "Dimension" + ":" }}</p>
              <div>
                <p
                  v-for="(key, index) in Object.keys(selectedDimensions)"
                  :key="key"
                >
                  {{
                    selectedDimensions[key] +
                      (index == Object.keys(selectedDimensions).length - 1
                        ? ""
                        : ",")
                  }}
                </p>
              </div>
              /
            </template>
          </div>
        </div>

        <template>
          <div
            v-if="selectedCategory === 'Pregunta'"
            class="openAnswers-content"
          >
            <template v-for="key in Object.keys(openAnswersData)">
              <div
                class="openAnswers-section"
                v-if="openAnswersData[key].length"
                :key="key"
              >
                <div class="openAnswers-category">{{ key }}</div>
                <div
                  class="openAnswers-answer"
                  v-for="(answer, index) in openAnswersData[key]"
                  :key="answer + key + index"
                >
                  {{ answer.answer }}
                </div>
              </div>
            </template>
          </div>

          <div v-else class="openAnswers-content">
            <template v-for="key in Object.keys(openAnswersData)">
              <div class="openAnswers-section" :key="key">
                <div class="openAnswers-category">{{ key }}</div>
                <template v-for="key2 in Object.keys(openAnswersData[key])">
                  <div
                    :key="key2 + key"
                    v-if="openAnswersData[key][key2].length"
                  >
                    <div class="openAnswers-category">
                      {{ key2 }}
                    </div>
                    <div
                      class="openAnswers-answer"
                      v-for="(answer, index) in openAnswersData[key][key2]"
                      :key="key + key2 + index"
                    >
                      {{ answer }}
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </div>

      <vue-html2pdf
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        :pdf-quality="2"
        pdf-content-width="90%"
        :htmlToPdfOptions="{
          margin: [5, 20, 0, 0],
          filename: `respuestas_abiertas.pdf`,
          html2canvas: {
            onclone: element => {
              const svgElements = Array.from(element.querySelectorAll('svg'));
              svgElements.forEach(s => {
                const bBox = s.getBBox();
                s.setAttribute('x', bBox.x);
                s.setAttribute('y', bBox.y);
                s.setAttribute('width', bBox.width);
                s.setAttribute('height', bBox.height);
                s.setAttribute('color', '#0054B4');
                s.setAttribute('fill', '#0054B4');
              });
            }
          }
        }"
        ref="printMeOA"
      >
        <section slot="pdf-content">
          <div>
            <template slot="title">Respuestas abiertas</template>
            <div class="openAnswers-title">
              <div class="logo-container">
                <svg
                  id="Capa_1"
                  data-name="Capa 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1161.25 526.9"
                  ref="logo"
                >
                  <path
                    class="cls-1"
                    fill="#0054B4"
                    d="M360.28,389H177.52q-.28,7.59-.28,13.22,0,30.36,16.17,45.83a54,54,0,0,0,38.66,15.46q41.9,0,50.33-44.7l75.91,5.62q-31.77,98.4-129,98.41-42.46,0-72.12-17t-48.92-49.63Q89,423.63,89,378.08q0-67.77,38.66-109.37T227,227.09q54.83,0,94,38.66T360.28,389Zm-183-48.64H278.18q-3.94-58.48-48.08-58.48Q182.87,281.92,177.24,340.4Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-1"
                    fill="#0054B4"
                    d="M413.14,517V133.19h83.78V517Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-1"
                    fill="#0054B4"
                    d="M647.06,133.19V271.61q23.06-44.54,76.76-44.52,28.68,0,49.06,12.52t28.68,32.19q8.3,19.68,8.3,57.64V517H726.07V356.78q0-28.71-2.25-39.41a32.4,32.4,0,0,0-10.22-17.88q-8-7.17-20-7.17-15.71,0-31.09,12.26t-15.42,52.2V517H565.81V133.19Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-1"
                    fill="#0054B4"
                    d="M1126.72,517h-81.25q-3.95-16.87-3.94-44.42Q1020.2,502.91,999,512.89t-48.15,10q-39.86,0-64.44-21.65T861.87,443a89.9,89.9,0,0,1,14.32-49.21q14.29-22.49,46-35.28t115.65-19V323.25q0-41.33-41.22-41.33-44,0-49,41.33l-77.32-7.31q7.61-48.36,45.83-68.6t86.6-20.25a213.14,213.14,0,0,1,54.55,6.75q25.58,6.75,39.92,20.24t19.4,28.54q5.07,15,5.06,52.44V463.83Q1121.66,500.94,1126.72,517Zm-88.84-136.08q-87.72,8.72-87.73,53.14a38.19,38.19,0,0,0,9.26,25.72q9.24,10.83,26.34,10.83,22.14,0,37.13-18.42t15-45.4Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-2"
                    fill="#3d3f3e"
                    d="M1243.45,503.2c-16.1,8.77-27.74,15.5-39.74,21.52-7.68,3.85-8.67,7.41-2.18,13.55,10.68,10.09,20.52,21.07,31.38,31,7.13,6.49,9.66,11.21.78,18.59-10.8,9-19.77,26-30.88,27.21-11.7,1.28-20.36-17-30.48-26.61-5.69-5.38-10-12.22-16.63-20.54l-29,51.31L1085.6,462.28Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M316.94,622.81v21.9h-14v-59h23.24q7.77,0,11.9,1.66a17.57,17.57,0,0,1,7.36,5.94,16.88,16.88,0,0,1,3.24,10.5,21.84,21.84,0,0,1-1.73,8.51,15.3,15.3,0,0,1-4.88,6.52,17.94,17.94,0,0,1-6.11,3.15,39.73,39.73,0,0,1-9.27.78Zm-.48-10.71h9c3.42,0,5.83-.8,7.21-2.38a8,8,0,0,0,2.07-5.44,7.35,7.35,0,0,0-2.31-5.7c-1.54-1.42-3.88-2.12-7-2.12h-8.94Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M355.68,644.71v-59h12.88v59Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M417.2,644.71H404.71a31.3,31.3,0,0,1-.6-6.82q-3.28,4.67-6.54,6.19a17,17,0,0,1-7.39,1.54,14.39,14.39,0,0,1-9.9-3.33,11.32,11.32,0,0,1-3.78-8.94,13.84,13.84,0,0,1,2.2-7.56,14.47,14.47,0,0,1,7.08-5.42q4.86-2,17.77-2.92V615q0-6.36-6.34-6.35c-4.5,0-7,2.11-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,417.2,644.71ZM403.55,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4.05,1.66,7.07,7.07,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M441.56,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17c1.18,0,2.54-.06,4.09-.18v9.55a55.92,55.92,0,0,1-8.08.95q-8,0-11.4-3.09t-3.37-11.16V611.1h-6.91v-10h7.64l1-12.87,11.1-.87v13.74h9.37v10Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M497,644.71H484.5a31.22,31.22,0,0,1-.61-6.82q-3.27,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.53,6.35l-11.88-1.13q1.16-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.07,30.07,0,0,0,497,644.71ZM483.33,623.8q-13.47,1.35-13.48,8.17a5.89,5.89,0,0,0,1.43,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M520.27,611.1v33.61H507.74V611.1h-5.06v-10h5.06a41.24,41.24,0,0,1,1-9.83,10.08,10.08,0,0,1,4.56-5.48q3.68-2.4,10-2.4a43,43,0,0,1,6,.48v9.93c-1.85-.14-3.37-.22-4.58-.22-1.76,0-2.92.47-3.5,1.39a8.77,8.77,0,0,0-.86,4.49v1.64h7.9v10Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M552.6,600.17q10,0,15.56,6.53T573.74,623q0,9.68-5.8,16.13t-15.43,6.46q-9.32,0-14.91-6.4T532,622.81A25.43,25.43,0,0,1,534.54,611a20.93,20.93,0,0,1,6.93-7.84A19.46,19.46,0,0,1,552.6,600.17Zm.35,8.43a6.15,6.15,0,0,0-5.52,2.83q-1.84,2.82-1.85,11.25c0,5.15.59,8.86,1.76,11.1a5.83,5.83,0,0,0,5.52,3.37,6.22,6.22,0,0,0,5.3-2.87q2-2.88,2-10.95,0-8.6-1.78-11.67A5.9,5.9,0,0,0,553,608.6Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M594.35,644.71H581.87V601.08h11.79v8.12q2.72-9.08,9.42-9.07c.63,0,1.41,0,2.33.13V613a12.67,12.67,0,0,0-6.11,1.21,8.73,8.73,0,0,0-3.57,4.34,21.32,21.32,0,0,0-1.38,8.45Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M674.91,644.71H662V620.56a44.41,44.41,0,0,0-.3-6.63,4.24,4.24,0,0,0-1.58-2.55,5.54,5.54,0,0,0-3.52-1,7.62,7.62,0,0,0-4.32,1.51q-2.21,1.51-2.2,8.68v24.15H637.25V620.56a28.89,28.89,0,0,0-.48-6.76,4.7,4.7,0,0,0-1.79-2.48,5.62,5.62,0,0,0-3.35-1,5.88,5.88,0,0,0-5,2.09q-1.62,2.1-1.62,8.1v24.15H612.58V601.08h12.09v6.65q3.08-7.56,12.29-7.56,8.35,0,12.07,7.56,3.63-7.56,12.66-7.56a14.77,14.77,0,0,1,7.43,1.77,10.42,10.42,0,0,1,4.45,4.63c.9,1.9,1.34,5.17,1.34,9.8Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M723.55,644.71H711.07a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.54,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.84,13.84,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.77,0-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.49,30.49,0,0,0,723.55,644.71ZM709.9,623.8q-13.47,1.35-13.47,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M756.12,585.75h18q11,0,16.4,3a28.51,28.51,0,0,1,9.67,9.63q4.27,6.6,4.27,16.66,0,12.64-7.46,21.14t-20.59,8.49H756.12Zm13.57,10.84v37h5.69q7.54,0,11.21-5.64a25.24,25.24,0,0,0,1.57-23.15,13.84,13.84,0,0,0-5.19-6.4,14.45,14.45,0,0,0-7.46-1.83Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M825.76,584.54v12.87H812.89V584.54Zm0,16.54v43.63H812.89V601.08Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M879.93,602.07a17.44,17.44,0,0,0-3.41-.43q-4.28,0-5,3.8a11.74,11.74,0,0,1,3.49,8.3,11.5,11.5,0,0,1-5.16,9.85q-5.16,3.66-14.88,3.67a34.16,34.16,0,0,1-6.74-.66,3.83,3.83,0,0,0-2.2,3.34,2.89,2.89,0,0,0,1.86,3c1.23.49,4.22.85,8.94,1.08a117,117,0,0,1,13.86,1.25,11.45,11.45,0,0,1,6.4,3.54,9.92,9.92,0,0,1,2.59,7q0,6-5.55,10.15t-19.68,4.15q-13.26,0-17.88-2.87c-3.08-1.93-4.62-4.35-4.62-7.3q0-5.82,7.47-7.87-5.53-2.76-5.53-7.77,0-6.27,8.73-9.76c-5.16-2.14-7.74-5.68-7.74-10.67a11.6,11.6,0,0,1,5.34-9.87q5.34-3.81,14.75-3.81a35.6,35.6,0,0,1,11.53,1.9,12.1,12.1,0,0,1,2.19-6.11q1.79-2.4,7.62-2.4a18.47,18.47,0,0,1,3.63.35Zm-33.65,42.34a3.36,3.36,0,0,0-2,1,3.24,3.24,0,0,0-1.19,2.51c0,2,1.26,3.18,3.76,3.67a46.5,46.5,0,0,0,8.82.74q6.6,0,9.26-1c1.77-.66,2.66-1.72,2.66-3.19,0-1.13-.74-2-2.23-2.6s-5.43-.9-11.85-.9l-2.6,0a24.19,24.19,0,0,1-3.19-.13A8.81,8.81,0,0,0,846.28,644.41Zm8.57-37.28a7.22,7.22,0,0,0-5.19,1.79,6.24,6.24,0,0,0-1.87,4.73q0,6.66,7.11,6.65a7.32,7.32,0,0,0,5.4-1.94,6.39,6.39,0,0,0,2-4.71,6.46,6.46,0,0,0-1.85-4.56A7.3,7.3,0,0,0,854.85,607.13Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M898.16,584.54v12.87H885.29V584.54Zm0,16.54v43.63H885.29V601.08Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M923.26,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17q1.77,0,4.08-.18v9.55a55.67,55.67,0,0,1-8.07.95q-8,0-11.41-3.09t-3.37-11.16V611.1h-6.91v-10h7.65l1-12.87,11.11-.87v13.74h9.37v10Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M978.68,644.71H966.19a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.31,11.31,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.4,14.4,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.54,6.35l-11.88-1.13q1.17-7.43,7-10.54a28.08,28.08,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.68,10.68,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,978.68,644.71ZM965,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.05,7.05,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
                    transform="translate(-88.96 -133.19)"
                  />
                  <path
                    class="cls-3"
                    fill="#3d3f3e"
                    d="M988.53,644.71v-59h12.87v59Z"
                    transform="translate(-88.96 -133.19)"
                  />
                </svg>
              </div>
              <h1>{{ selectedCategory }}</h1>
            </div>
            <div class="report-filters">
              <h1>Filtros seleccionados:</h1>
              <div>
                <template v-for="key in Object.keys(filters)"
                  >/
                  <p :key="key + 'p'">{{ " " + key + ":" }}</p>
                  <div :key="key + 'div'">
                    <p v-for="(filter, index) in filters[key]" :key="filter">
                      {{
                        filter + (index == filters[key].length - 1 ? "" : ",")
                      }}
                    </p>
                  </div>
                </template>
                <template v-if="Object.keys(selectedDimensions).length">
                  /
                  <p>{{ " " + "Dimension" + ":" }}</p>
                  <div>
                    <p
                      v-for="(key, index) in Object.keys(selectedDimensions)"
                      :key="key"
                    >
                      {{
                        selectedDimensions[key] +
                          (index == Object.keys(selectedDimensions).length - 1
                            ? ""
                            : ",")
                      }}
                    </p>
                  </div>
                  /
                </template>
              </div>
            </div>

            <template>
              <div
                v-if="selectedCategory === 'Pregunta'"
                class="openAnswers-content"
              >
                <template v-for="key in Object.keys(openAnswersData)">
                  <div
                    class="openAnswers-section"
                    v-if="openAnswersData[key].length"
                    :key="key"
                  >
                    <div class="openAnswers-category">{{ key }}</div>
                    <div
                      class="openAnswers-answer"
                      v-for="(answer, index) in openAnswersData[key]"
                      :key="answer + key + index"
                    >
                      {{ answer.answer }}
                    </div>
                  </div>
                </template>
              </div>

              <div v-else class="openAnswers-content">
                <template v-for="key in Object.keys(openAnswersData)">
                  <div class="openAnswers-section" :key="key">
                    <div class="openAnswers-category">{{ key }}</div>
                    <template v-for="key2 in Object.keys(openAnswersData[key])">
                      <div
                        :key="key2 + key"
                        v-if="openAnswersData[key][key2].length"
                      >
                        <div class="openAnswers-category">
                          {{ key2 }}
                        </div>
                        <div
                          class="openAnswers-answer"
                          v-for="(answer, index) in openAnswersData[key][key2]"
                          :key="key + key2 + index"
                        >
                          {{ answer }}
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </section>
      </vue-html2pdf>
    </modal>
  </div>
</template>

<script>
import jspdf from "jspdf";
import Modal from "../../components/Modal.vue";
import BarChart from "../charts/BarChart.vue";
import LineChart from "../charts/LineChart.vue";
import ClockChart from "../charts/ClockChart.vue";
import CustomButton from "../CustomButton.vue";
import {
  faFileDownload,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint
} from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    BarChart,
    LineChart,
    ClockChart,
    Modal,
    CustomButton
  },
  data() {
    return {
      icons: {
        fileDownload: faFileDownload,
        excel: faFileExcel,
        pdf: faFilePdf,
        powerpoint: faFilePowerpoint
      }
    };
  },

  props: {
    chartType: {
      type: String,
      default: "",
      required: false
    },
    selectedCategory: {
      type: String,
      required: true
    },
    // categories: {
    //   type: Array,
    //   required: true,
    // },
    questions: {
      type: Array,
      required: true
    },
    selectedDisplay: {
      type: String,
      required: true
    },
    survey: {
      type: Object,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    isReportModalOpen: {
      type: Boolean,
      required: true
    },
    isOpenAnswersModalOpen: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    participation: {
      type: Array,
      required: true
    },
    satisfactionIndex: {
      type: Array,
      required: true
    },
    limits: {
      type: Array,
      required: true
    },
    selectedDimensions: {
      type: Object,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    openAnswersData: {
      required: true
    }
  },

  mounted() {},

  methods: {
    downloadAsPDF() {
      const el = this.$refs.printMe;
      const logo = this.$refs.logo;
      logo.setAttribute("width", logo.getBoundingClientRect().width);
      logo.setAttribute("height", logo.getBoundingClientRect().height);
      logo.setAttribute("color", this.colors[1]);

      logo.style.width = null;
      logo.style.height = null;
      this.$html2canvas(el).then(canvas => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jspdf("p", "pt", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const pdf2 = new jspdf("p", "pt", [
          pdf.internal.pageSize.getWidth(),
          pdfHeight + 20 > pdf.internal.pageSize.getHeight()
            ? pdfHeight + 20
            : pdf.internal.pageSize.getHeight()
        ]);
        pdf2.addImage(
          imgData,
          "PNG",
          (pdf2.internal.pageSize.getWidth() - pdfWidth) / 2,
          10,
          pdfWidth,
          pdfHeight
        );
        pdf2.save("reporte.pdf");
      });
    },

    downloadAsPDFOA() {
      // const logo = this.$refs.logo;
      // logo.setAttribute("color", this.colors[1]);
      // logo.setAttribute("width", logo.getBoundingClientRect().width);
      // logo.setAttribute("height", logo.getBoundingClientRect().height);
      let element = this.$refs.printMeOA.$el;
      const svgElements = Array.from(element.querySelectorAll("svg"));
      svgElements.forEach(s => {
        s.setAttribute("color", "#0054B4");
        s.setAttribute("fill", "#0054B4");
      });

      this.$refs.printMeOA.generatePdf({ margin: 10 });
      // const el = this.$refs.printMeOA;
      // const logo = this.$refs.logo;
      // logo.setAttribute("width", logo.getBoundingClientRect().width);
      // logo.setAttribute("height", logo.getBoundingClientRect().height);
      // logo.setAttribute("color", this.colors[1]);

      // logo.style.width = null;
      // logo.style.height = null;
      // this.$html2canvas(el).then(canvas => {
      //   const imgData = canvas.toDataURL("image/png");
      //   const pdf = new jspdf("p", "pt", "a4");
      //   const imgProps = pdf.getImageProperties(imgData);
      //   const pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
      //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      //   const pdf2 = new jspdf("p", "pt", [
      //     pdf.internal.pageSize.getWidth(),
      //     pdfHeight + 20
      //   ]);
      //   pdf2.addImage(
      //     imgData,
      //     "PNG",
      //     (pdf2.internal.pageSize.getWidth() - pdfWidth) / 2,
      //     10,
      //     pdfWidth,
      //     pdfHeight
      //   );
      //   pdf2.save("respuestas_abiertas.pdf");
      // });
    },

    CSVtoArray(text) {
      var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // Return NULL if input string is not well formed CSV string.
      if (!re_valid.test(text)) return null;
      var a = []; // Initialize array to receive values.
      text.replace(
        re_value, // "Walk" the string using replace with callback.
        function(m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return ""; // Return empty string.
        }
      );
      // Handle special case of empty last value.
      if (/,\s*$/.test(text)) a.push("");
      return a.join(" ");
    },

    getReportCSV() {
      let csv = [];
      csv.push([
        "Participación #",
        "Participación %",
        "Resultado General",
        "Dimensiones",
        "Preguntas"
      ]);

      csv.push(
        "\n" + this.participation[1],
        this.participation[0] + "%",
        this.satisfactionIndex[0] + "%",
        this.survey.sections.length,
        this.survey.questions.length
      );

      if (this.selectedDisplay === "Análisis de Resultados") {
        csv.push(["\n \n" + this.selectedCategory, "Fortaleza", "Oportunidad"]);
        let temp = [];
        Object.keys(this.chartData.dataset).forEach(category => {
          temp.push(
            "\n" + this.CSVtoArray(category),
            ...this.chartData.dataset[category].map(option => option + "%")
          );
        });
        csv.push(temp);
      } else {
        csv.push([
          "\n \n" + this.selectedCategory,
          ...this.survey.scale.map(scale => this.CSVtoArray(scale.text))
        ]);
        let temp = [];
        Object.keys(this.chartData.dataset).forEach(category => {
          temp.push(
            "\n" + this.CSVtoArray(category),
            ...this.chartData.dataset[category].map(option => option + "%")
          );
        });
        csv.push(temp);
      }

      return csv;
    },

    getReportCSVOA() {
      let csv = [];

      if (this.selectedCategory === "Pregunta") {
        let temp = [];
        Object.keys(this.openAnswersData).forEach(key => {
          if (this.openAnswersData[key].length) {
            temp.push(this.CSVtoArray(key));
            this.openAnswersData[key].forEach(answer => {
              temp.push("\n" + this.CSVtoArray(answer.answer));
            });
          }
        });
        csv.push(temp);
      } else {
        let temp = [];
        Object.keys(this.openAnswersData).forEach((key, cont) => {
          if (cont === 0) temp.push(this.CSVtoArray(key));
          else temp.push("\n" + this.CSVtoArray(key));
          Object.keys(this.openAnswersData[key]).forEach(key2 => {
            if (this.openAnswersData[key][key2].length) {
              temp.push("\n" + this.CSVtoArray(key2));
              this.openAnswersData[key][key2].forEach(answer => {
                temp.push("\n" + this.CSVtoArray(answer));
              });
            }
          });
        });
        csv.push(temp);
      }
      return csv;
    }
  },

  computed: {
    tableLengthStyle() {
      let header = [];
      if (this.selectedDisplay === "Análisis de Resultados") {
        header = [this.selectedCategory, "Fortaleza", "Oportunidad"];
      } else {
        header = [this.selectedCategory, ...this.survey.scale];
      }
      return { "--header-length": header.length };
    },

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    downloadLinkOA() {
      let csvFileData = this.getReportCSVOA();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    isShapeCircle() {
      return this.chartType === "roundBar";
    }

    // chartData() {
    //   let datasetAndLegend;
    //   if (this.selectedDisplay === "Análisis de Resultados") {
    //     switch (this.selectedCategory) {
    //       case "Dimension":
    //         datasetAndLegend = this.getSatisfactionIndexByDimensions();
    //         break;
    //       case "Pregunta":
    //         datasetAndLegend = this.getSatisfactionIndexByQuestions();
    //         break;
    //       default:
    //         datasetAndLegend = this.getSatisfactionIndexByCategories();
    //         break;
    //     }
    //   } else {
    //     switch (this.selectedCategory) {
    //       case "Dimension":
    //         datasetAndLegend = this.getAnswersOfOptionByDimensions();
    //         break;
    //       case "Pregunta":
    //         datasetAndLegend = this.getAnswersOfOptionByQuestions();
    //         break;
    //       default:
    //         datasetAndLegend = this.getAnswersOfOptionByCategories();
    //         break;
    //     }
    //   }
    //   return {
    //     dataset: datasetAndLegend.dataset,
    //     legend: datasetAndLegend.legend,
    //   };
    // },
  }
};
</script>

<style scoped>
.charts * {
  font-weight: var(--weight-semi-bold);
}

.container {
  height: 100%;
  font-size: 16px;
}

.charts {
  height: 100%;
  width: 100%;
}

.report-table {
  margin: auto;
}

.row {
  display: grid;
  grid-template-columns: repeat(var(--header-length), 1fr);
  border-right: 1px solid var(--grayColor2);
  border-left: 1px solid var(--grayColor2);
  border-bottom: 1px solid var(--grayColor2);
  font-size: 0.8rem;
}

.report-data-table .row {
  grid-template-columns: repeat(5, 1fr);
}

.header.row {
  border-top: 1px solid var(--grayColor2);
  border-top-left-radius: var(--lengthSm2);
  border-top-right-radius: var(--lengthSm2);
  color: white;
  color: var(--fontColor3);
  font-weight: var(--semi-bold);
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.row span {
  padding: var(--lengthSm3);
  font-weight: var(--medium);
}

.row span:not(:last-child) {
  border-right: 1px solid var(--grayColor2);
}

.report-filters {
  text-align: center;
  margin-bottom: var(--lengthMd2);
  margin-top: var(--lengthSm2);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.report-filters > div {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: center;
  min-width: 95%;
  max-width: 95%;
  flex-wrap: wrap;
}

.report-filters h1 {
  font-size: 1rem;
  font-weight: bold;
}

.report-filters p {
  font-size: 0.85rem;
}

.report-filters > div > div > p {
  font-weight: bold;
}

.report-filters div {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.limit-bad {
  color: #fd7670;
}

.limit-medium {
  color: #f3c319;
}

.limit-good {
  color: #15b195;
}

h1 {
  font-size: 1.4em;
}

.report-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.report-title .logo-container {
  height: 1.8em;
  position: absolute;
  right: 1em;
  top: 0;
}

.report-title * {
  font-size: 1.2rem;
}

.report-data-table .row > span {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.report-table .row > span {
  display: flex;
  align-items: center;
}

.report-table .row > span:not(:first-child) {
  text-align: center;
  justify-content: center;
  text-align: center;
}

.openAnswers-title {
  text-align: center;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.openAnswers-title .logo-container {
  height: 1.8em;
  position: absolute;
  right: 1em;
  top: 0;
}

.openAnswers-title * {
  font-size: 1.2rem;
}

.openAnswers-category {
  font-size: 0.92rem;
  color: var(--fontColor1);
  font-weight: 600;
  margin-bottom: 0.8em;
}

.openAnswers-section {
  margin-bottom: 1.2em;
}

.openAnswers-answer {
  font-size: 0.875rem;
  margin-bottom: 0.6em;
  border: solid 2px #f3f3f4;
  border-radius: 8px;
  padding: 1em;
  background-color: #fafafa;
}

.file-buttons {
  display: flex;
  gap: 1em;
}

/* LOGO CONTAINER */
.logo-container {
  grid-area: logo;
  margin: 0.5em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo-container svg {
  height: 100%;
}

.logo-container .cls-1 {
  fill: var(--primary-selected-color);
}
.logo-container .cls-2,
.logo-container .cls-3 {
  fill: #3d3f3e;
}
.logo-container .cls-2 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

@media (max-height: 1000px) {
  .container {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}
</style>
