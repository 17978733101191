<template>
  <div class="container" @click="$emit('click')">
    <div>
      <font-awesome-icon :icon="icons.limiter" slot="icon" />
    </div>
  </div>
</template>

<script>
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "ChartSelect",
  data() {
    return {
      icons: {
        limiter: faTachometerAlt
      }
    };
  },
  props: {},

  methods: {},
  computed: {},
  mounted() {}
};
</script>

<style scoped>
.container {
  padding: 0.477em;
  background-color: var(--grayColor1);
  border-radius: 4px;
  cursor: pointer;
}

.container:hover {
  background-color: var(--grayColor2);
}

.container svg {
  height: 27px;
  width: 27px;
}
</style>
