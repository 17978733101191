<template>
  <div
    class="dashboard"
    :style="colorStyle"
    v-if="
      categories &&
        selectedSurvey &&
        selectedCategory &&
        dataComplete &&
        !loading
    "
    ref="printMe"
  >
    <div
      class="dashboard-container"
      :class="{
        confidential: isConfidential,
        eNPSdisabledd: !selectedSurvey.isEnpsEnabled,
      }"
      v-if="Object.keys(selectedSurvey).length"
    >
      <div class="tools-container">
        <div class="title">
          <h1>Dashboard</h1>
          <h3>Resultados</h3>
        </div>

        <div class="tools-buttons">
          <template
            v-for="index in selectedDisplay == 'Análisis de Resultados'
              ? 2
              : selectedSurvey.scale.length"
          >
            <div :key="index">
              <palette
                :startColor="selectedSurvey.colors[index - 1] || 'gray'"
                @changeColor="(color) => changeColor(color, index - 1)"
              />
            </div>
          </template>

          <div>
            <chart-select
              v-model="chartType"
              :charts="['squareBar', 'roundBar', 'line', 'clockBar']"
            />
          </div>

          <div>
            <limit-select @click="setEditLimitOptionsModal" />
          </div>

          <collapse label="Información">
            <h4 slot="tittle">Escala</h4>
            <div slot="content">
              <h4>Fortaleza</h4>
              <div
                v-for="(scale, index) in selectedSurvey.scale.filter(
                  (el) => el.isPositive,
                )"
                :key="index + scale"
                class="tag"
              >
                <h5>{{ scale.text }}</h5>
              </div>
              <h4>Oportunidad</h4>
              <div
                slot="content2"
                v-for="(scale, index) in selectedSurvey.scale.filter(
                  (el) => !el.isPositive,
                )"
                :key="index + scale + 2"
                class="tag"
              >
                <h5>{{ scale.text }}</h5>
              </div>
            </div>

            <h4 slot="tittle2">Participacion</h4>
            <div slot="content2">
              <h4>Digital</h4>
              <p>
                {{
                  (
                    ((filteredAnswersLength - totalWrittenAnswers) /
                      filteredAnswersLength) *
                    100
                  ).toFixed(0)
                }}%
              </p>
              <h4>No digital</h4>
              <p>
                {{
                  ((totalWrittenAnswers / filteredAnswersLength) * 100).toFixed(
                    0,
                  )
                }}%
              </p>
            </div>

            <h4 slot="tittle3">Datos</h4>
            <div slot="content3">
              <h4>Periodos</h4>
              <p>
                {{ surveys.length }}
              </p>
              <h4>Escala</h4>
              <p>
                {{ selectedSurvey.scale.length }}
              </p>
              <h4>Dimensiones</h4>
              <p>
                {{ selectedSurvey.sections.length }}
              </p>
              <h4>Preguntas</h4>
              <p>
                {{ selectedSurvey.questions.length }}
              </p>
            </div>
          </collapse>

          <custom-button variant="secondary" @click="downloadPPT">
            <font-awesome-icon :icon="icons.powerpoint" slot="icon" />
            PPT
          </custom-button>

          <custom-button
            variant="secondary"
            :to="{
              name: 'GeneralReport',
              params: { surveyName: selectedSurvey.name },
            }"
            target="_blank"
          >
            Reporte
          </custom-button>

          <period-select
            :surveys="surveys"
            :selectedSurveyName="selectedSurvey.name"
            @input="changeSurvey"
          />

          <custom-button variant="button" @click="isModalOpen = true">
            <font-awesome-icon :icon="icons.filter" slot="icon" />
            Filtrar
          </custom-button>

          <!-- <custom-button
            variant="button"
            download="reporte_general.csv"
            :href="downloadLink"
          >
            Excel
          </custom-button>  -->
        </div>
      </div>

      <div class="tags-container">
        <tag-bar :filters="filters" :selectedDimensions="selectedDimensions" />
      </div>

      <template v-if="!isConfidential">
        <div class="participation-container">
          <participation-chart
            :filters="filters"
            :participation="participation[1]"
            :totalParticipation="participation[0]"
            :totalWrittenAnswers="totalWrittenAnswers"
            :surveyId="selectedSurvey.id"
            :participationData="participationData"
          />
        </div>

        <div class="doughnut-chart-container">
          <doughnut-chart-satisfaction
            :scale="selectedSurvey.scale"
            :colors="selectedSurvey.colors"
            :satisfactionIndexOfCompany="satisfactionIndexOfCompany"
            :isEnpsEnabled="selectedSurvey.isEnpsEnabled"
          />
        </div>

        <div class="ENPS-chart-container" v-if="selectedSurvey.isEnpsEnabled">
          <doughnut-chart-ENPS
            :survey="selectedSurvey"
            :colors="selectedSurvey.colors"
            :filters="filters"
            :questions="selectedSurvey.questions"
            :participation="participation"
            :satisfactionIndex="satisfactionIndexOfCompany"
            :selectedDimensions="selectedDimensions"
            :eNPS="eNPSData.eNPS"
            :eNPSPerAnswer="eNPSData.eNPSPerAnswer"
            :eNPSSatisfactionIndex="eNPSData.eNPSSatisfactionIndex"
            :filteredAnswersLength="filteredAnswersLength"
          />
        </div>

        <div class="main-chart-container">
          <div class="main-chart-text">
            <div class="main-chart-display">
              <custom-select
                :options="['Respuestas por Opción', 'Análisis de Resultados']"
                @input="setDashboardData"
                v-model="selectedDisplay"
              ></custom-select>
              <h3>{{ selectedCategory }}</h3>
            </div>
            <div>
              <div class="display-btn" @click="isOpenAnswersModalOpen = true">
                <font-awesome-icon :icon="icons.openAnswers" slot="icon" />
              </div>
              <div class="display-btn" @click="isReportModalOpen = true">
                <font-awesome-icon :icon="icons.plusCircle" slot="icon" />
              </div>
            </div>
          </div>

          <div class="main-chart" ref="mainChart">
            <main-chart
              ref="mainChartComponent"
              :chartData="chartData"
              :selectedCategory="selectedCategory"
              :categories="categories"
              :survey="selectedSurvey"
              :selectedDisplay="selectedDisplay"
              :colors="selectedSurvey.colors"
              :chartType="chartType"
              :questions="selectedSurvey.questions"
              :isReportModalOpen="isReportModalOpen"
              :isOpenAnswersModalOpen="isOpenAnswersModalOpen"
              :filters="filters"
              :participation="participation"
              :satisfactionIndex="satisfactionIndexOfCompany"
              :limits="selectedSurvey.limits ? selectedSurvey.limits : [60, 80]"
              :selectedDimensions="selectedDimensions"
              :openAnswersData="openAnswersData"
              @close="
                isReportModalOpen = false;
                isOpenAnswersModalOpen = false;
              "
            ></main-chart>
          </div>
        </div>

        <div class="logo-company">
          <img :src="company.logoURL" alt="" />
        </div>

        <div class="data">
          <div class="data-section">
            <h2>Periodos</h2>
            <h1 class="secondary-color">{{ surveys.length }}</h1>
          </div>
          <div class="data-section">
            <h2>Escala</h2>
            <h1 class="secondary-color">{{ selectedSurvey.scale.length }}</h1>
          </div>
          <div class="data-section">
            <h2>Dimensiones</h2>
            <h1 class="primary-color">
              {{ selectedSurvey.sections.length }}
            </h1>
          </div>
          <div class="data-section">
            <h2>Preguntas</h2>
            <h1 class="primary-color">
              {{ selectedSurvey.questions.length }}
            </h1>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="empty-state-confidential">
          <font-awesome-icon :icon="icons.confidential" slot="icon" />
          <p>
            La plataforma te permite visualizar resultados a partir de grupos de
            {{ selectedSurvey.minRequire || 4 }}
            personas
          </p>
        </div>
      </template>

      <div class="logo-container">
        <svg
          id="Capa_1"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1161.25 526.9"
        >
          <path
            class="cls-1"
            d="M360.28,389H177.52q-.28,7.59-.28,13.22,0,30.36,16.17,45.83a54,54,0,0,0,38.66,15.46q41.9,0,50.33-44.7l75.91,5.62q-31.77,98.4-129,98.41-42.46,0-72.12-17t-48.92-49.63Q89,423.63,89,378.08q0-67.77,38.66-109.37T227,227.09q54.83,0,94,38.66T360.28,389Zm-183-48.64H278.18q-3.94-58.48-48.08-58.48Q182.87,281.92,177.24,340.4Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-1"
            d="M413.14,517V133.19h83.78V517Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-1"
            d="M647.06,133.19V271.61q23.06-44.54,76.76-44.52,28.68,0,49.06,12.52t28.68,32.19q8.3,19.68,8.3,57.64V517H726.07V356.78q0-28.71-2.25-39.41a32.4,32.4,0,0,0-10.22-17.88q-8-7.17-20-7.17-15.71,0-31.09,12.26t-15.42,52.2V517H565.81V133.19Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-1"
            d="M1126.72,517h-81.25q-3.95-16.87-3.94-44.42Q1020.2,502.91,999,512.89t-48.15,10q-39.86,0-64.44-21.65T861.87,443a89.9,89.9,0,0,1,14.32-49.21q14.29-22.49,46-35.28t115.65-19V323.25q0-41.33-41.22-41.33-44,0-49,41.33l-77.32-7.31q7.61-48.36,45.83-68.6t86.6-20.25a213.14,213.14,0,0,1,54.55,6.75q25.58,6.75,39.92,20.24t19.4,28.54q5.07,15,5.06,52.44V463.83Q1121.66,500.94,1126.72,517Zm-88.84-136.08q-87.72,8.72-87.73,53.14a38.19,38.19,0,0,0,9.26,25.72q9.24,10.83,26.34,10.83,22.14,0,37.13-18.42t15-45.4Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-2"
            d="M1243.45,503.2c-16.1,8.77-27.74,15.5-39.74,21.52-7.68,3.85-8.67,7.41-2.18,13.55,10.68,10.09,20.52,21.07,31.38,31,7.13,6.49,9.66,11.21.78,18.59-10.8,9-19.77,26-30.88,27.21-11.7,1.28-20.36-17-30.48-26.61-5.69-5.38-10-12.22-16.63-20.54l-29,51.31L1085.6,462.28Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M316.94,622.81v21.9h-14v-59h23.24q7.77,0,11.9,1.66a17.57,17.57,0,0,1,7.36,5.94,16.88,16.88,0,0,1,3.24,10.5,21.84,21.84,0,0,1-1.73,8.51,15.3,15.3,0,0,1-4.88,6.52,17.94,17.94,0,0,1-6.11,3.15,39.73,39.73,0,0,1-9.27.78Zm-.48-10.71h9c3.42,0,5.83-.8,7.21-2.38a8,8,0,0,0,2.07-5.44,7.35,7.35,0,0,0-2.31-5.7c-1.54-1.42-3.88-2.12-7-2.12h-8.94Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M355.68,644.71v-59h12.88v59Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M417.2,644.71H404.71a31.3,31.3,0,0,1-.6-6.82q-3.28,4.67-6.54,6.19a17,17,0,0,1-7.39,1.54,14.39,14.39,0,0,1-9.9-3.33,11.32,11.32,0,0,1-3.78-8.94,13.84,13.84,0,0,1,2.2-7.56,14.47,14.47,0,0,1,7.08-5.42q4.86-2,17.77-2.92V615q0-6.36-6.34-6.35c-4.5,0-7,2.11-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,417.2,644.71ZM403.55,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4.05,1.66,7.07,7.07,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M441.56,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17c1.18,0,2.54-.06,4.09-.18v9.55a55.92,55.92,0,0,1-8.08.95q-8,0-11.4-3.09t-3.37-11.16V611.1h-6.91v-10h7.64l1-12.87,11.1-.87v13.74h9.37v10Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M497,644.71H484.5a31.22,31.22,0,0,1-.61-6.82q-3.27,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.53,6.35l-11.88-1.13q1.16-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.07,30.07,0,0,0,497,644.71ZM483.33,623.8q-13.47,1.35-13.48,8.17a5.89,5.89,0,0,0,1.43,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M520.27,611.1v33.61H507.74V611.1h-5.06v-10h5.06a41.24,41.24,0,0,1,1-9.83,10.08,10.08,0,0,1,4.56-5.48q3.68-2.4,10-2.4a43,43,0,0,1,6,.48v9.93c-1.85-.14-3.37-.22-4.58-.22-1.76,0-2.92.47-3.5,1.39a8.77,8.77,0,0,0-.86,4.49v1.64h7.9v10Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M552.6,600.17q10,0,15.56,6.53T573.74,623q0,9.68-5.8,16.13t-15.43,6.46q-9.32,0-14.91-6.4T532,622.81A25.43,25.43,0,0,1,534.54,611a20.93,20.93,0,0,1,6.93-7.84A19.46,19.46,0,0,1,552.6,600.17Zm.35,8.43a6.15,6.15,0,0,0-5.52,2.83q-1.84,2.82-1.85,11.25c0,5.15.59,8.86,1.76,11.1a5.83,5.83,0,0,0,5.52,3.37,6.22,6.22,0,0,0,5.3-2.87q2-2.88,2-10.95,0-8.6-1.78-11.67A5.9,5.9,0,0,0,553,608.6Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M594.35,644.71H581.87V601.08h11.79v8.12q2.72-9.08,9.42-9.07c.63,0,1.41,0,2.33.13V613a12.67,12.67,0,0,0-6.11,1.21,8.73,8.73,0,0,0-3.57,4.34,21.32,21.32,0,0,0-1.38,8.45Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M674.91,644.71H662V620.56a44.41,44.41,0,0,0-.3-6.63,4.24,4.24,0,0,0-1.58-2.55,5.54,5.54,0,0,0-3.52-1,7.62,7.62,0,0,0-4.32,1.51q-2.21,1.51-2.2,8.68v24.15H637.25V620.56a28.89,28.89,0,0,0-.48-6.76,4.7,4.7,0,0,0-1.79-2.48,5.62,5.62,0,0,0-3.35-1,5.88,5.88,0,0,0-5,2.09q-1.62,2.1-1.62,8.1v24.15H612.58V601.08h12.09v6.65q3.08-7.56,12.29-7.56,8.35,0,12.07,7.56,3.63-7.56,12.66-7.56a14.77,14.77,0,0,1,7.43,1.77,10.42,10.42,0,0,1,4.45,4.63c.9,1.9,1.34,5.17,1.34,9.8Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M723.55,644.71H711.07a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.54,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.34,11.34,0,0,1-3.77-8.94,13.84,13.84,0,0,1,2.2-7.56,14.44,14.44,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.77,0-7.53,6.35l-11.88-1.13q1.17-7.43,7-10.54a28,28,0,0,1,13.3-3.11,32.77,32.77,0,0,1,8.38,1,14,14,0,0,1,6.14,3.11,10.78,10.78,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30.49,30.49,0,0,0,723.55,644.71ZM709.9,623.8q-13.47,1.35-13.47,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.09,7.09,0,0,0,5.71-2.83,10.64,10.64,0,0,0,2.3-7Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M756.12,585.75h18q11,0,16.4,3a28.51,28.51,0,0,1,9.67,9.63q4.27,6.6,4.27,16.66,0,12.64-7.46,21.14t-20.59,8.49H756.12Zm13.57,10.84v37h5.69q7.54,0,11.21-5.64a25.24,25.24,0,0,0,1.57-23.15,13.84,13.84,0,0,0-5.19-6.4,14.45,14.45,0,0,0-7.46-1.83Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M825.76,584.54v12.87H812.89V584.54Zm0,16.54v43.63H812.89V601.08Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M879.93,602.07a17.44,17.44,0,0,0-3.41-.43q-4.28,0-5,3.8a11.74,11.74,0,0,1,3.49,8.3,11.5,11.5,0,0,1-5.16,9.85q-5.16,3.66-14.88,3.67a34.16,34.16,0,0,1-6.74-.66,3.83,3.83,0,0,0-2.2,3.34,2.89,2.89,0,0,0,1.86,3c1.23.49,4.22.85,8.94,1.08a117,117,0,0,1,13.86,1.25,11.45,11.45,0,0,1,6.4,3.54,9.92,9.92,0,0,1,2.59,7q0,6-5.55,10.15t-19.68,4.15q-13.26,0-17.88-2.87c-3.08-1.93-4.62-4.35-4.62-7.3q0-5.82,7.47-7.87-5.53-2.76-5.53-7.77,0-6.27,8.73-9.76c-5.16-2.14-7.74-5.68-7.74-10.67a11.6,11.6,0,0,1,5.34-9.87q5.34-3.81,14.75-3.81a35.6,35.6,0,0,1,11.53,1.9,12.1,12.1,0,0,1,2.19-6.11q1.79-2.4,7.62-2.4a18.47,18.47,0,0,1,3.63.35Zm-33.65,42.34a3.36,3.36,0,0,0-2,1,3.24,3.24,0,0,0-1.19,2.51c0,2,1.26,3.18,3.76,3.67a46.5,46.5,0,0,0,8.82.74q6.6,0,9.26-1c1.77-.66,2.66-1.72,2.66-3.19,0-1.13-.74-2-2.23-2.6s-5.43-.9-11.85-.9l-2.6,0a24.19,24.19,0,0,1-3.19-.13A8.81,8.81,0,0,0,846.28,644.41Zm8.57-37.28a7.22,7.22,0,0,0-5.19,1.79,6.24,6.24,0,0,0-1.87,4.73q0,6.66,7.11,6.65a7.32,7.32,0,0,0,5.4-1.94,6.39,6.39,0,0,0,2-4.71,6.46,6.46,0,0,0-1.85-4.56A7.3,7.3,0,0,0,854.85,607.13Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M898.16,584.54v12.87H885.29V584.54Zm0,16.54v43.63H885.29V601.08Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M923.26,611.1v18.45c0,2.3.34,3.84,1,4.62s2.31,1.17,4.86,1.17q1.77,0,4.08-.18v9.55a55.67,55.67,0,0,1-8.07.95q-8,0-11.41-3.09t-3.37-11.16V611.1h-6.91v-10h7.65l1-12.87,11.11-.87v13.74h9.37v10Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M978.68,644.71H966.19a31.3,31.3,0,0,1-.6-6.82q-3.29,4.67-6.53,6.19a17.06,17.06,0,0,1-7.4,1.54,14.43,14.43,0,0,1-9.9-3.33,11.31,11.31,0,0,1-3.77-8.94,13.76,13.76,0,0,1,2.2-7.56,14.4,14.4,0,0,1,7.07-5.42q4.88-2,17.77-2.92V615q0-6.36-6.33-6.35-6.76,0-7.54,6.35l-11.88-1.13q1.17-7.43,7-10.54a28.08,28.08,0,0,1,13.31-3.11,32.83,32.83,0,0,1,8.38,1,14,14,0,0,1,6.13,3.11,10.68,10.68,0,0,1,3,4.39,28.42,28.42,0,0,1,.78,8v19.79A30,30,0,0,0,978.68,644.71ZM965,623.8q-13.49,1.35-13.48,8.17a5.88,5.88,0,0,0,1.42,3.95,5,5,0,0,0,4,1.66,7.05,7.05,0,0,0,5.7-2.83,10.65,10.65,0,0,0,2.31-7Z"
            transform="translate(-88.96 -133.19)"
          />
          <path
            class="cls-3"
            d="M988.53,644.71v-59h12.87v59Z"
            transform="translate(-88.96 -133.19)"
          />
        </svg>
      </div>
    </div>

    <modal :isOpen="isModalOpen" @close="isModalOpen = false">
      <template slot="title">Filtrar</template>
      <div class="filters-container">
        <filter-box
          :startCategory="selectedCategory || ''"
          :startDimensions="selectedDimensions || {}"
          :startFilters="filters || {}"
          v-on:editFilters="handleEditFilter"
          :categories="[
            ...categories.slice(1, categories.length),
            {
              name: 'Dimension',
              options: selectedSurvey.sections.filter(
                (section) => section.id !== 'enps',
              ),
            },
            categories[0],
          ]"
          :scale="selectedSurvey.scale"
          v-on:closeModal="isModalOpen = false"
        ></filter-box>
      </div>
    </modal>

    <modal :isOpen="isLimitModalOpen" @close="isLimitModalOpen = false">
      <template slot="title">Limite</template>
      <validation-observer tag="div" v-slot="{ handleSubmit, invalid }">
        <form
          @submit.prevent="handleSubmit(editSurveyLimits)"
          class="surveyLimitsForm"
        >
          <custom-input
            :label="'Critico'"
            type="number"
            v-model="editLimitOption.critic"
            rules="required|positive"
          />
          <custom-input
            :label="'Semicritico'"
            type="number"
            v-model="editLimitOption.semiCritic"
            rules="required|positive"
          />
          <div class="btn-container">
            <custom-button
              type="button"
              :variant="'text'"
              @click="isLimitModalOpen = false"
            >
              Cancelar
            </custom-button>

            <custom-button type="submit" :disabled="invalid">
              Aceptar
            </custom-button>
          </div>
        </form>
      </validation-observer>
    </modal>

    <div :style="{ display: 'none' }">
      <font-awesome-icon
        class="primary-color"
        :icon="icons.smileFace"
        slot="icon"
        ref="smileFace"
      />
      <font-awesome-icon
        class="secondary-color"
        :icon="icons.neutralFace"
        slot="icon"
        ref="neutralFace"
      />
      <font-awesome-icon
        class="nth-color"
        :icon="icons.sadFace"
        slot="icon"
        ref="sadFace"
      />
    </div>
  </div>
  <div class="loading-screen" v-else-if="loading"><loading-screen /></div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import MainChart from '../components/dashboard/MainChart.vue';
import DoughnutChartSatisfaction from '../components/dashboard/DoughnutChartSatisfaction.vue';
import DoughnutChartENPS from '../components/dashboard/DoughnutChartENPS.vue';
import FilterBox from '../components/dashboard/FilterBox.vue';
import CustomSelect from '../components/dashboard/CustomSelectChart.vue';
import ParticipationChart from '../components/dashboard/ParticipationChart.vue';
import PeriodSelect from '../components/dashboard/PeriodSelect.vue';
import ChartSelect from '../components/dashboard/ChartSelect.vue';
import LimitSelect from '../components/dashboard/LimitSelect.vue';
import Palette from '../components/dashboard/Palette.vue';
import TagBar from '../components/dashboard/TagBar.vue';
import Collapse from '../components/dashboard/Collapse.vue';
import Modal from '../components/Modal.vue';
import CustomButton from '../components/CustomButton.vue';
import CustomInput from '@/components/CustomInput.vue';
import LoadingScreen from '../components/LoadingScreen.vue';
import pptxgen from 'pptxgenjs';
//import print from "print-js";

import {
  faFileDownload,
  faSlidersH,
  faPlusCircle,
  faComment,
  faFilePowerpoint,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';

import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
//import download from "downloadjs";
export default {
  components: {
    MainChart,
    FilterBox,
    Modal,
    CustomSelect,
    DoughnutChartSatisfaction,
    DoughnutChartENPS,
    Palette,
    ParticipationChart,
    ChartSelect,
    TagBar,
    Collapse,
    CustomButton,
    LimitSelect,
    CustomInput,
    ValidationObserver,
    LoadingScreen,
    PeriodSelect,
  },
  data() {
    return {
      loading: false,
      participationData: {},
      chartData: {},
      chartType: 'squareBar',
      isModalOpen: false,
      filters: {},
      selectedDimensions: {},
      selectedCategory: '',
      selectedDisplay: 'Respuestas por Opción',
      categories: [],
      dataComplete: true,
      isReportModalOpen: false,
      isLimitModalOpen: false,
      isOpenAnswersModalOpen: false,
      editLimitOption: {
        critic: 0,
        semiCritic: 0,
      },
      icons: {
        fileDownload: faFileDownload,
        filter: faSlidersH,
        plusCircle: faPlusCircle,
        openAnswers: faComment,
        powerpoint: faFilePowerpoint,
        confidential: faUserLock,
        sadFace: faFrown,
        neutralFace: faMeh,
        smileFace: faSmile,
      },
      satisfactionIndexOfCompany: [0, 0],
      totalWrittenAnswers: 0,
      filteredAnswersLength: 0,
      openAnswersData: {},
      eNPSData: {},
    };
  },

  methods: {
    ...mapActions([
      'fetchCurrentSurvey',
      'fetchSections',
      'fetchAllAnswers',
      'getParticipationData',
      'fetchAllSurveys',
      'getDashboardData',
      'updateParticipationData',
      'updateSurveyColors',
      'updateSurveyLimits',
    ]),
    ...mapActions('users', ['fetchUsers']),

    ...mapActions('sections', ['fetchSections', 'fetchQuestions']),

    ...mapActions('scale', ['fetchScale']),

    ...mapActions('filters', ['fetchFilters']),

    ...mapMutations(['setCurrentSurvey']),

    // getReportCSV() {
    //   let csvContent = 'Nombre,Pregunta,Respuesta\n';

    //   // Iterate through each object in the array
    //   this.answers.forEach((item) => {
    //     const userId = item.userId;
    //     const userName = this.users.users.find(({ id }) => id === userId).name;

    //     // Iterate through the 'multipleChoiceAnswers' array in each object
    //     item.multipleChoiceAnswers.forEach((answer) => {
    //       const questionText = answer.question.text;
    //       const answerText = answer.answer.text;

    //       // Append the data to the CSV content
    //       csvContent += `${userName},"${questionText}","${answerText}"\n`;
    //     });
    //     const questionText = item.enpsAnswer.question?.text || 'No se tiene enps';
    //     const answerText = item.enpsAnswer.answer;
    //     csvContent += `${userName},"${questionText}","${answerText}"\n`;
    //   });

    //   return csvContent;
    // },

    setEditLimitOptionsModal() {
      if (this.selectedSurvey.limits) {
        this.editLimitOption.critic = this.selectedSurvey.limits[0] + '';
        this.editLimitOption.semiCritic = this.selectedSurvey.limits[1] + '';
      } else {
        this.editLimitOption.critic = 60;
        this.editLimitOption.semiCritic = 80;
      }
      this.isLimitModalOpen = true;
    },

    editSurveyLimits() {
      let limits = [
        Number(this.editLimitOption.critic),
        Number(this.editLimitOption.semiCritic),
      ];
      this.updateSurveyLimits(limits);
      this.selectedSurvey.limits = limits;
      this.isLimitModalOpen = false;
    },

    rgbToHex(r, g, b) {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },

    randomColor(r, g, b) {
      var maxMin = this.getMaxMin(r);
      var rN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      maxMin = this.getMaxMin(g);
      var gN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      maxMin = this.getMaxMin(b);
      var bN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      return this.rgbToHex(rN, gN, bN);
    },
    getMaxMin(value) {
      var max = value + 30 < 255 ? value + 30 : 255;
      var min = value + 80 < 255 ? value + 80 : 255;
      return [max, min];
    },
    generateColors() {
      if (!this.selectedSurvey.colors) {
        let tempColors = ['#0054B4', '#7097FF'];
        for (let i = 0; i < this.selectedSurvey.scale.length; i++) {
          tempColors.push(this.randomColor(104, 208, 158));
        }
        this.updateSurveyColors(tempColors);
        this.selectedSurvey.colors = tempColors;
      }
    },
    async changeSurvey(survey) {
      this.loading = true;
      this.dataComplete = false;
      await this.setCurrentSurvey(survey);
      await this.fetchSections();
      await this.fetchScale();
      await this.fetchFilters();
      await this.fetchQuestions();
      await this.fetchUsers();
      await this.fetchAllAnswers();
      this.setCategories();
      if (
        !['Dimension', ...this.categories.map((cat) => cat.name)].includes(
          this.selectedCategory,
        )
      ) {
        alert(
          'El periodo ' +
            this.selectedSurvey.name +
            ' no tiene el filtro ' +
            this.selectedCategory,
        );
        this.selectedCategory = 'Dimension';
      }
      this.dataComplete = true;
      await this.setData();
      this.loading = false;
    },
    changeColor(color, index) {
      let colorsCopy = [...this.selectedSurvey.colors];
      colorsCopy[index] = color;
      this.updateSurveyColors(colorsCopy);
      this.selectedSurvey.colors = colorsCopy;
    },
    async handleEditFilter(filters) {
      this.filters = { ...filters.selectedFilters };
      this.selectedDimensions = { ...filters.selectedDimensions };
      this.selectedCategory = filters.selectedCategory;
      this.isModalOpen = false;
      this.chartType = 'squareBar';
      await this.setDashboardData();
    },

    setCategories() {
      this.categories = [
        {
          id: 'question',
          name: 'Pregunta',
          options: this.selectedSurvey.questions,
        },
        ...this.cat.sort((x, y) =>
          x.orgHierarchy === y.orgHierarchy
            ? x.hasParent === y.hasParent
              ? 0
              : x.hasParent
              ? 1
              : -1
            : x.orgHierarchy
            ? -1
            : 1,
        ),
      ];
      this.selectedCategory = 'Dimension';
    },

    async setDashboardData() {
      if (!this.loading) this.loading = true;
      try {
        let dashboardData = await this.getDashboardData({
          surveyId: this.selectedSurvey.id,
          selectedDisplay: this.selectedDisplay,
          categories: this.categories,
          selectedCategory: this.selectedCategory,
          filters: this.filters,
          selectedDimensions: this.selectedDimensions,
        });
        this.chartData = dashboardData.chartData;
        this.satisfactionIndexOfCompany =
          dashboardData.companySatisfactionIndex;
        this.totalWrittenAnswers = dashboardData.totalWrittenAnswers;
        this.filteredAnswersLength = dashboardData.filteredAnswersLength;
        this.openAnswersData = dashboardData.openAnswersData;
        this.eNPSData = dashboardData.eNPSData;
      } catch (error) {
        console.log('Error getDashboardData cloud function: ' + error);
      }
      this.loading = false;
    },

    // async setParticipationData() {
    //   if (!this.loading) this.loading = true;
    //   try {
    //     this.participationData = await this.getParticipationData({
    //       surveyId: this.selectedSurvey.id,
    //       categories: [...this.cat].filter(cat => cat.orgHierarchy)
    //     });
    //   } catch (error) {
    //     console.log("Error getParticipationData cloud function: " + error);
    //   }
    // },

    async setData() {
      if (!this.loading) this.loading = true;
      try {
        let result = await Promise.all([
          this.getParticipationData({
            surveyId: this.selectedSurvey.id,
            categories: [...this.cat].filter((cat) => cat.orgHierarchy),
          }),
          this.getDashboardData({
            surveyId: this.selectedSurvey.id,
            selectedDisplay: this.selectedDisplay,
            categories: this.categories,
            selectedCategory: this.selectedCategory,
            filters: this.filters,
            selectedDimensions: this.selectedDimensions,
          }),
        ]);
        this.participationData = result[0];
        let dashboardData = result[1];

        //activar para actualizar participation data
        // this.participationData = await this.getParticipationData({
        //   surveyId: this.selectedSurvey.id,
        //   categories: [...this.cat].filter((cat) => cat.orgHierarchy),
        // });

        // let dashboardData = await this.getDashboardData({
        //   surveyId: this.selectedSurvey.id,
        //   selectedDisplay: this.selectedDisplay,
        //   categories: this.categories,
        //   selectedCategory: this.selectedCategory,
        //   filters: this.filters,
        //   selectedDimensions: this.selectedDimensions,
        // });
        this.chartData = dashboardData.chartData;
        this.satisfactionIndexOfCompany =
          dashboardData.companySatisfactionIndex;
        this.totalWrittenAnswers = dashboardData.totalWrittenAnswers;
        this.filteredAnswersLength = dashboardData.filteredAnswersLength;
        this.openAnswersData = dashboardData.openAnswersData;
        this.eNPSData = dashboardData.eNPSData;
      } catch (error) {
        console.log('Error getDashboardData cloud function: ' + error);
      }
      this.loading = false;
    },

    downloadPPT() {
      let pptx = new pptxgen();
      let slide = pptx.addSlide();

      //5.62 tamaño ppt
      slide.addText('Informe de Resultados', {
        x: 0,
        y: 2.61,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 32,
      });

      let text = '';
      Object.keys(this.filters).forEach((key) => {
        text += ' / ' + key + ': ';
        this.filters[key].forEach((filter, index) => {
          text += filter + (index == this.filters[key].length - 1 ? '' : ', ');
        });
      });

      if (Object.keys(this.selectedDimensions).length) {
        text += ' / Dimension: ';
        Object.keys(this.selectedDimensions).forEach((key, index) => {
          text +=
            this.selectedDimensions[key] +
            (index == Object.keys(this.selectedDimensions).length - 1
              ? ''
              : ', ');
        });
        text += ' / ';
      }

      slide.addText(text, {
        x: 0,
        y: 3.1,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 14,
      });
      slide = pptx.addSlide();
      let mainChartData = [];

      this.chartData.legend.forEach((label, index) => {
        let labels = Object.keys(this.chartData.dataset).sort((a, b) =>
          parseInt(this.chartData.dataset[a][0]) >
          parseInt(this.chartData.dataset[b][0])
            ? -1
            : parseInt(this.chartData.dataset[b][0]) >
              parseInt(this.chartData.dataset[a][0])
            ? 1
            : 0,
        );
        let values = [];
        labels.forEach((label) =>
          values.push(this.chartData.dataset[label][index] / 100),
        );
        mainChartData.push({
          name: label,
          labels: labels,
          values: values,
        });
      });

      slide.addText([{ text: text }], {
        x: 0,
        y: 1,
        w: '100%',
        h: 0.1,
        fontSize: 8,
        align: 'center',
      });
      slide.addChart(
        this.chartType == 'line' ? pptx.ChartType.line : pptx.ChartType.bar,
        mainChartData,
        {
          x: 1,
          y: 1.2,
          w: 8,
          h: 4.3,
          catAxisLabelColor: '000000',
          catAxisLabelFontSize: 8,
          catAxisOrientation: 'minMax',
          valAxisLabelFontSize: 8,
          dataLabelFontSize: 8,
          legendFontSize: 8,
          titleFontSize: 10,
          chartColors: this.selectedSurvey.colors,
          dataLabelPosition: 't',
          dataLabelFormatCode: '#%',
          valAxisLabelFormatCode: '#%',
          showValue: true,
          showTitle: true,
          showLegend: true,
          legendPos: 't',
          valAxisMaxVal: 1,
          title: this.selectedDisplay + ': ' + this.selectedCategory,
        },
      );
      let border = { pt: '2', color: '0054B4' };

      slide.addTable(
        [
          [
            {
              text: this.participation[0] + '%',
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                bold: true,
              },
            },
          ],
          [
            {
              text: this.participation[1] + ' respondidos',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                fontSize: 8,
              },
            },
          ],
        ],
        {
          x: 2.5,
          y: 0.3,
          w: 1.5,
          color: '0054B4',
          align: 'center',
          border: { pt: '2', color: '0054B4' },
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[0] };
      slide.addTable(
        [
          [
            {
              text: 'Fortaleza',
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                fontSize: 8,
              },
            },
          ],
          [
            {
              text: this.satisfactionIndexOfCompany[0] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 4.2,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[0],
          align: 'left',
          border: { pt: '2', color: this.selectedSurvey.colors[0] },
        },
      );

      border = { pt: '2', color: this.selectedSurvey.colors[1] };
      slide.addTable(
        [
          [
            {
              text: 'Oportunidad',
              options: {
                border: [border, border, null, border],
                fontSize: 8,
                margin: [0.12, 0.12, 0, 0.12],
              },
            },
          ],
          [
            {
              text: this.satisfactionIndexOfCompany[1] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 5.9,
          y: 0.3,
          w: 1.5,
          color: this.selectedSurvey.colors[1],
          align: 'left',
        },
      );

      const generalDoughnutData = [
        {
          name: 'Resultado General',
          labels: ['Fortaleza', 'Oportunidad'],
          values: this.satisfactionIndexOfCompany,
        },
      ];

      slide.addChart(pptx.charts.DOUGHNUT, generalDoughnutData, {
        x: 5.1,
        y: 0.3,
        w: 0.57,
        h: 0.57,
        chartColors: [this.selectedSurvey.colors[0], 'F5F6FA'],
        dataLabelColor: 'FFFFFF',
        dataLabelFontSize: 14,
        holeSize: 80,
        legendPos: 'b',
        showPercent: false,
        showLegend: false,
        showTitle: false,
        dataLabelPosition: 'ctr',
        titleFontFace: 'Helvetica Neue',
        titleFontSize: 10,
        title: 'Resultado General',
      });

      slide.addChart(pptx.charts.DOUGHNUT, generalDoughnutData, {
        x: 6.8,
        y: 0.3,
        w: 0.57,
        h: 0.57,
        chartColors: ['F5F6FA', this.selectedSurvey.colors[1]],
        dataLabelColor: 'FFFFFF',
        dataLabelFontSize: 14,
        holeSize: 80,
        legendPos: 'b',
        showPercent: false,
        showLegend: false,
        showTitle: false,
        dataLabelPosition: 'ctr',
        titleFontFace: 'Helvetica Neue',
        titleFontSize: 10,
        title: 'Resultado General',
      });

      if (this.selectedSurvey.isEnpsEnabled) {
        slide = pptx.addSlide();

        const eNPSDoughnutData = [
          {
            name: 'Resultado General',
            labels: ['Detractores', 'Pasivos', 'Promotores'],
            values: this.eNPSData.eNPSSatisfactionIndex,
          },
        ];

        let svgElement = this.$refs.smileFace;
        svgElement.setAttribute('color', this.selectedSurvey.colors[0]);
        let s = new XMLSerializer().serializeToString(svgElement);
        let smileFaceImgData = 'data:image/svg+xml;base64,' + window.btoa(s);

        svgElement = this.$refs.neutralFace;
        svgElement.setAttribute('color', this.selectedSurvey.colors[1]);
        s = new XMLSerializer().serializeToString(svgElement);
        let neutralFaceImgData = 'data:image/svg+xml;base64,' + window.btoa(s);

        svgElement = this.$refs.sadFace;
        svgElement.setAttribute('color', '#FD7670');
        s = new XMLSerializer().serializeToString(svgElement);
        let sadFaceImgData = 'data:image/svg+xml;base64,' + window.btoa(s);

        slide.addText(
          this.selectedSurvey.questions.filter(
            (question) => question.section.id === 'enps',
          )[0].text,
          {
            x: 1.7,
            y: 3.4,
            w: 6.6,
            h: 0.4,
            align: 'center',
            fontSize: 12,
          },
        );

        let gap = 0.33;
        for (let i = 0; i < 7; i++) {
          gap = 0.33 * i;
          slide.addText(i || '0', {
            x: 1.7 + gap + 0.3 * i,
            y: 3.8,
            w: 0.3,
            h: 0.3,
            fontSize: 12,
            color: 'FD7670',
            align: 'center',
          });
          slide.addImage({
            x: 1.7 + gap + 0.3 * i,
            y: 4.1,
            w: 0.3,
            h: 0.3,
            data: sadFaceImgData,
          });
          slide.addText(this.eNPSData.eNPSPerAnswer[i] + ' Votos', {
            x: 1.55 + gap + 0.3 * i,
            y: 4.45,
            w: 0.6,
            h: 0.3,
            fontSize: 10,
            color: '818891',
            align: 'center',
          });
        }
        for (let i = 7; i < 9; i++) {
          gap = 0.33 * i;
          slide.addText(i, {
            x: 1.7 + gap + 0.3 * i,
            y: 3.8,
            w: 0.3,
            h: 0.3,
            fontSize: 12,
            color: this.selectedSurvey.colors[1],
            align: 'center',
          });
          slide.addImage({
            x: 1.7 + gap + 0.3 * i,
            y: 4.1,
            w: 0.3,
            h: 0.3,
            data: neutralFaceImgData,
          });
          slide.addText(this.eNPSData.eNPSPerAnswer[i] + ' Votos', {
            x: 1.55 + gap + 0.3 * i,
            y: 4.45,
            w: 0.6,
            h: 0.3,
            fontSize: 10,
            color: '818891',
            align: 'center',
          });
        }

        for (let i = 9; i < 11; i++) {
          gap = 0.33 * i;
          slide.addText(i, {
            x: 1.7 + gap + 0.3 * i - (i == 10 ? 0.05 : 0),
            y: 3.8,
            w: 0.3 + (i == 10 ? 0.1 : 0),
            h: 0.3,
            fontSize: 12,
            color: this.selectedSurvey.colors[0],
            align: 'center',
          });
          slide.addImage({
            x: 1.7 + gap + 0.3 * i,
            y: 4.1,
            w: 0.3,
            h: 0.3,
            data: smileFaceImgData,
          });
          slide.addText(this.eNPSData.eNPSPerAnswer[i] + ' Votos', {
            x: 1.55 + gap + 0.3 * i,
            y: 4.45,
            w: 0.6,
            h: 0.3,
            fontSize: 10,
            color: '818891',
            align: 'center',
          });
        }

        border = { pt: '2', color: 'FD7670' };

        slide.addTable(
          [
            [
              {
                text: 'Detractores',
                options: {
                  border: [border, null, null, null],
                  margin: [0.08, 0, 0, 0],
                },
              },
            ],
          ],
          {
            x: 1.7,
            y: 4.9,
            w: 4.16,
            h: 0.3,
            fontSize: 10,
            color: 'FD7670',
            align: 'center',
          },
        );

        border = { pt: '2', color: this.selectedSurvey.colors[1] };
        slide.addTable(
          [
            [
              {
                text: 'Pasivos',
                options: {
                  border: [border, null, null, null],
                  margin: [0.08, 0, 0, 0],
                },
              },
            ],
          ],
          {
            x: 1.7 + 4.16 + 0.11,
            y: 4.9,
            w: 1.11,
            h: 0.3,
            fontSize: 10,
            color: this.selectedSurvey.colors[1],
            align: 'center',
          },
        );

        border = { pt: '2', color: this.selectedSurvey.colors[0] };
        slide.addTable(
          [
            [
              {
                text: 'Promotores',
                options: {
                  border: [border, null, null, null],
                  margin: [0.08, 0, 0, 0],
                },
              },
            ],
          ],
          {
            x: 1.7 + 4.16 + 1.11 + 0.11 * 2,
            y: 4.9,
            w: 1.11,
            h: 0.3,
            fontSize: 10,
            color: this.selectedSurvey.colors[0],
            align: 'center',
          },
        );

        slide.addChart(pptx.charts.DOUGHNUT, eNPSDoughnutData, {
          x: 1,
          y: 1,
          w: 2,
          h: 2,
          holeSize: 70,
          catAxisLabelColor: '000000',
          catAxisLabelFontSize: 8,
          catAxisOrientation: 'minMax',
          valAxisLabelFontSize: 8,
          dataLabelFontSize: 8,
          legendFontSize: 8,
          titleFontSize: 10,
          chartColors: [
            'FD7670',
            this.selectedSurvey.colors[1],
            this.selectedSurvey.colors[0],
          ],
          // dataLabelPosition: "t",
          // dataLabelFormatCode: "#%",
          // valAxisLabelFormatCode: "#%",
          showPercent: false,
          showValue: false,
          showTitle: false,
          showLegend: false,
          legendPos: 't',
          valAxisMaxVal: 1,
          title: 'eNPS',
        });

        slide.addText(this.eNPSData.eNPS, {
          x: 1.35,
          y: 1.65,
          h: 0.5,
          w: 1.3,
          align: 'center',
          fontSize: 42,
          color: this.selectedSurvey.colors[0],
        });
        slide.addText(this.eNPSMeasure, {
          x: 1.35,
          y: 2.1,
          h: 0.25,
          w: 1.3,
          align: 'center',
          fontSize: 12,
          color: this.selectedSurvey.colors[0],
        });

        slide.addText('Resultados eNPS', {
          x: 1.7,
          y: 0.4,
          w: 6.6,
          h: 0.4,
          align: 'center',
          fontSize: 16,
        });

        slide.addText('Detractores', {
          x: 3.5,
          y: 1.15,
          h: 0.25,
          w: 1.3,
          align: 'center',
          fontSize: 12,
          color: 'FD7670',
          line: { width: '2', color: 'FD7670' },
        });

        slide.addText(
          (
            (this.eNPSData.eNPSSatisfactionIndex[0] *
              this.filteredAnswersLength) /
            100
          ).toFixed(0),
          {
            x: 5.2,
            y: 1.15,
            h: 0.25,
            w: 0.7,
            align: 'center',
            fontSize: 12,
            color: 'FD7670',
            line: { width: '2', color: 'FD7670' },
          },
        );

        slide.addText(this.eNPSData.eNPSSatisfactionIndex[0].toFixed(0) + '%', {
          x: 6.3,
          y: 1.15,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          color: 'FD7670',
          line: { width: '2', color: 'FD7670' },
        });

        slide.addText('Pasivos', {
          x: 3.5,
          y: 1.15 + 0.25 + 0.466,
          h: 0.25,
          w: 1.3,
          align: 'center',
          fontSize: 12,
          color: this.selectedSurvey.colors[1],
          line: { width: '2', color: this.selectedSurvey.colors[1] },
        });

        slide.addText(
          (
            (this.eNPSData.eNPSSatisfactionIndex[1] *
              this.filteredAnswersLength) /
            100
          ).toFixed(0),
          {
            x: 5.2,
            y: 1.15 + 0.25 + 0.466,
            h: 0.25,
            w: 0.7,
            align: 'center',
            fontSize: 12,
            color: this.selectedSurvey.colors[1],
            line: { width: '2', color: this.selectedSurvey.colors[1] },
          },
        );

        slide.addText(this.eNPSData.eNPSSatisfactionIndex[1].toFixed(0) + '%', {
          x: 6.3,
          y: 1.15 + 0.25 + 0.466,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          color: this.selectedSurvey.colors[1],
          line: { width: '2', color: this.selectedSurvey.colors[1] },
        });

        slide.addText('Promotores', {
          x: 3.5,
          y: 1.15 + 0.25 * 2 + 0.466 * 2,
          h: 0.25,
          w: 1.3,
          align: 'center',
          fontSize: 12,
          color: this.selectedSurvey.colors[0],
          line: { width: '2', color: this.selectedSurvey.colors[0] },
        });

        slide.addText(
          (
            (this.eNPSData.eNPSSatisfactionIndex[2] *
              this.filteredAnswersLength) /
            100
          ).toFixed(0),
          {
            x: 5.2,
            y: 1.15 + 0.25 * 2 + 0.466 * 2,
            h: 0.25,
            w: 0.7,
            align: 'center',
            fontSize: 12,
            color: this.selectedSurvey.colors[0],
            line: { width: '2', color: this.selectedSurvey.colors[0] },
          },
        );

        slide.addText(this.eNPSData.eNPSSatisfactionIndex[2].toFixed(0) + '%', {
          x: 6.3,
          y: 1.15 + 0.25 * 2 + 0.466 * 2,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          color: this.selectedSurvey.colors[0],
          line: { width: '2', color: this.selectedSurvey.colors[0] },
        });

        slide.addText('Total', {
          x: 7.4,
          y: 1.15 + 0.45,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          line: { width: '2', color: '000000' },
        });

        slide.addText(this.filteredAnswersLength, {
          x: 7.4,
          y: 1.15 + 0.25 + 0.45 + 0.2,
          h: 0.25,
          w: 0.7,
          align: 'center',
          fontSize: 12,
          color: '818891',
          line: { width: '2', color: '818891' },
        });
      }
      pptx.writeFile({ fileName: 'dashboard.pptx' });
    },
  },

  async mounted() {
    this.loading = true;
    try {
      await this.fetchUsers();
      await this.fetchCurrentSurvey(this.company.id);
      await this.fetchSections();
      await this.fetchScale();
      await this.fetchFilters();
      await this.fetchQuestions();
      await this.fetchAllAnswers(); //
      await this.fetchAllSurveys(this.company.id);
    } catch (err) {
      console.log('error fetching');
    }

    this.generateColors();
    await this.setCategories();
    this.selectedCategory = 'Dimension';
    await this.setData();

    //this.selectedSurvey = this.surveys.find((survey) => survey.active);

    // let data = {};
    // Object.keys(this.participationData).forEach((dt) => {
    //   data[dt] = [
    //     this.participationData[dt].filteredUsers +
    //       this.participationData[dt].participationWrittenAnswers,
    //     this.participationData[dt].participation,
    //   ];
    // });
    // this.updateParticipationData(data);
    // console.log(this.selectedSurvey.participationData);

    //check duplicates answers
    // let data = [];
    // this.filteredUsers.forEach((user) => {
    //   data.push(user.id);
    // });
    // let answersTemp = this.answers.filter(
    //   (answer) => !data.includes(answer.userId)
    // );
    // console.log(answersTemp);
    // let ids = [];
    // let answersTemp = [];
    // this.answers.forEach((answer) => {
    //   if (!ids.includes(answer.userId)) {
    //     ids.push(answer.userId);
    //     answersTemp.push(answer);
    //   } else {
    //     console.log(answer);
    //     console.log(answersTemp.filter((aw) => aw.userId == answer.userId)[0]);
    //   }
    // });
  },

  async beforeDestroy() {
    let survey = this.surveys.filter((survey) => survey.current);
    await this.changeSurvey(survey[0]);
  },

  computed: {
    ...mapState(['company', 'surveys', 'survey']),
    ...mapState(['users', 'answers']),

    // downloadLink() {
    //   let csvFileData = this.getReportCSV();
    //   var csvContent = '\uFEFF' + csvFileData;
    //   var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    //   var url = URL.createObjectURL(blob);
    //   return url;
    // },
    ...mapState({
      cat: (state) => {
        return [...state.filters.filters];
      },
    }),

    eNPSMeasure() {
      if (
        parseFloat(this.eNPSData.eNPS) >= -100 &&
        parseFloat(this.eNPSData.eNPS) <= -10
      )
        return 'Criticos';
      else if (
        parseFloat(this.eNPSData.eNPS) > -10 &&
        parseFloat(this.eNPSData.eNPS) <= 0
      )
        return 'Alertas';
      else if (
        parseFloat(this.eNPSData.eNPS) > 0 &&
        parseFloat(this.eNPSData.eNPS) <= 10
      )
        return 'Promedio';
      else if (
        parseFloat(this.eNPSData.eNPS) > 10 &&
        parseFloat(this.eNPSData.eNPS) <= 30
      )
        return 'Superior';
      else return 'Excepcional';
    },

    isConfidential() {
      return this.filteredAnswersLength < (this.selectedSurvey.minRequire || 4);
    },

    filteredUsers() {
      let users = JSON.parse(JSON.stringify(this.users.users));
      for (let category in this.filters) {
        if (category !== 'Pregunta') {
          users = users.filter((user) =>
            this.filters[category].includes(user.filters[category]),
          );
        }
      }
      return users;
    },

    participation() {
      return [
        this.filteredUsers.length + this.totalWrittenAnswers === 0
          ? 0
          : (
              (this.filteredAnswersLength /
                (this.filteredUsers.length + this.totalWrittenAnswers)) *
              100
            ).toFixed(0),
        this.filteredAnswersLength,
      ];
    },

    ...mapState({
      selectedSurvey: (state) => {
        return {
          ...state.survey,
          // answers: [...state.answers],
          sections: state.sections.sections.filter(
            (section) => state.survey.isEnpsEnabled || section.id !== 'enps',
          ),
          questions: state.sections.questions,
          scale: state.scale.scale,
        };
      },
    }),

    colorStyle() {
      return {
        '--primary-selected-color': this.selectedSurvey.colors[0],
        '--secondary-selected-color': this.selectedSurvey.colors[1],
        '--nth2-selected-color': this.selectedSurvey.colors[2],
        '--nth3-selected-color': this.selectedSurvey.colors[3],
      };
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.survey.active) next('/');
      else next();
    });
  },
};
</script>

<style scoped>
.dashboard-container > * {
  background-color: white;
}

h5 {
  margin: 0;
}

.dashboard-container h3 {
  color: var(--fontColor3);
}

.dashboard {
  padding: var(--lengthMd2) var(--lengthLg1) 0 var(--lengthLg1);
}

/* CONTAINER */
.dashboard-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: 'tools tools tools tools' 'tag tag tag tag' 'logoCompany participation data ENPS' 'doughnut bar bar bar' 'doughnut bar bar bar';
  grid-template-rows: 9% 3% 31% 17% 30%;
  grid-template-columns: 24% 24% 25% 24%;
  column-gap: 1%;
  row-gap: 2%;
  font-size: 14px;
}

.dashboard-container.eNPSdisabledd {
  grid-template-areas: 'tools tools tools tools' 'tag tag tag tag' 'logoCompany doughnut data participation' 'bar bar bar bar' 'bar bar bar bar';
}

.dashboard-container.confidential {
  grid-template-areas: 'tools tools tools tools' 'tag tag tag tag' 'confidential confidential confidential confidential' 'confidential confidential confidential confidential' 'confidential confidential confidential confidential';
}

/* DATA */
.data {
  grid-area: data;
  display: grid;
  grid-template-columns: 50% 50%;
  flex-grow: 1;
  flex-flow: column;
  border: solid 3px #f7f7fc;
  text-align: center;
}

.data h2 {
  font-size: 1.2em;
}

.data h1 {
  font-size: 1.4em;
}

.data > div {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.data > div:first-child {
  border-right: solid 3px #f7f7fc;
  border-bottom: solid 3px #f7f7fc;
}

.data > div:nth-child(2) {
  border-bottom: solid 3px #f7f7fc;
}

.data > div:nth-child(3) {
  border-right: solid 3px #f7f7fc;
}

/* COMPANY LOGO */
.logo-company {
  grid-area: logoCompany;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

.logo-company img {
  height: 100%;
}

/* TOOLS */
.tools-container {
  grid-area: tools;
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding: 0 1em;
}

.tools-buttons {
  display: flex;
  align-items: flex-end;
}

.tools-buttons > * {
  margin: 0 0.8em;
}
/* DOUGHNUT */

.ENPS-chart-container {
  grid-area: ENPS;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

/* DOUGHNUT */

.doughnut-chart-container {
  grid-area: doughnut;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

/* PARTICIPATION CONTAINER */
.participation-container {
  grid-area: participation;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
  position: relative;
}

/* BAR CONTAINER */
.main-chart-container {
  padding: 1em 1em 0.2em 1em;
  grid-area: bar;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  height: 100%;
  border: solid 3px #f7f7fc;
}

.main-chart-container > div:nth-child(2) {
  flex-grow: 1;
  padding: 0.3em 0.3em 0 0.3em;
}

.main-chart-display h2 {
  margin-right: 1em;
}

.main-chart-container h3 {
  font-size: 1.2em;
}

.main-chart-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-chart-text > div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 1.3em;
  margin-right: 2%;
}

.display-btn {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

.display-btn svg {
  height: 100%;
  width: 100%;
  color: var(--gray-color1);
}

.main-chart {
  height: 82%;
}

/* LOGO CONTAINER */
.logo-container {
  grid-area: logo;
  margin: 0.5em;
  height: calc(100% - 1em);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo-container svg {
  max-height: 100%;
}

.logo-container .cls-1 {
  fill: var(--primary-selected-color);
}
.logo-container .cls-2,
.logo-container .cls-3 {
  fill: #3d3f3e;
}
.logo-container .cls-2 {
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

/* TAGS CONTAINER */
.tags-container {
  padding-left: 1em;
  grid-area: tag;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tag {
  margin-right: var(--lengthSm2);
  margin-bottom: var(--lengthSm2);
  margin-top: var(--lengthSm2);
  padding: 0.4rem var(--lengthSm3);
  border-radius: var(--lengthMd3);
  background-color: #ebeef0;
  display: flex;
  align-items: center;
  width: max-content;
}

.surveyLimitsForm {
  display: flex;
  flex-flow: column;
  gap: 1.2em;
}
.surveyLimitsForm .btn-container {
  display: flex;
  justify-content: space-between;
}

.loading-screen {
  flex-grow: 1;
}

.empty-state-confidential {
  grid-area: confidential;
  height: 100%;
  width: 40%;
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
}

.empty-state-confidential svg {
  height: 7em;
  width: 7em;
}

.primary-color {
  background-color: var(--primary-selected-color);
}

.secondary-color {
  background-color: var(--secondary-selected-color);
}

.nth-color {
  background-color: #fd7670;
}

h1.primary-color {
  color: var(--primary-selected-color);
  background-color: white;
}

h1.secondary-color {
  color: var(--secondary-selected-color);
  background-color: white;
}

@page {
  size: Legal landscape;
  margin: 2.5% 0;
}

@media (max-height: 1000px) {
  .dashboard-container > div {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .dashboard-container > div {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .dashboard-container > div {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .dashboard-container > div {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .dashboard-container > div {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .dashboard-container > div {
    font-size: 8px;
  }
}
</style>
