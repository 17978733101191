<template>
  <div class="container">
    <div class="legend">
      <div
        v-for="(label, index) in chartData.legend"
        :key="label"
        class="label"
      >
        <div
          class="color"
          :style="{
            backgroundColor: colors[index] || 'gray'
          }"
        ></div>
        <div>{{ label }}</div>
      </div>
    </div>
    <div class="inner-container">
      <div class="yAxis">
        <div>
          <div v-for="value in [100, 80, 60, 40, 20, 0]" :key="value">
            {{ value }} %
          </div>
        </div>
      </div>

      <div class="chart-container" ref="chart">
        <figure class="css-chart" :style="sizeStyle">
          <div class="chart-lines">
            <div
              v-for="value in ['100%', '80%', '60%', '40%', '20%', '0']"
              :key="value"
              :style="{ bottom: value }"
            ></div>
          </div>
          <template
            v-for="val in chartData.dataset[Object.keys(chartData.dataset)[0]]
              .length"
          >
            <ul
              class="line-chart"
              :key="val"
              :style="{ '--color': colors[val - 1] }"
            >
              <li
                v-for="(el, index) in getData(val - 1)"
                :key="index"
                :style="{
                  '--x': getX(index, getData(val - 1)) + 'px',
                  '--y': getY(index, getData(val - 1)) + 'px'
                }"
              >
                <div
                  class="data-point"
                  :data-value="getData(val - 1)[index]"
                ></div>
                <div
                  class="line-segment"
                  :style="
                    index === getData(val - 1).length - 1
                      ? { '--hypotenuse': 0, '--angle': 0 }
                      : {
                          '--hypotenuse': getHypotenuse(
                            index,
                            getData(val - 1)
                          ),
                          '--angle': getAngle(index, getData(val - 1))
                        }
                  "
                ></div>
                <div class="value">{{ el }}%</div>
              </li>
            </ul>
          </template>
        </figure>

        <div class="labels" :style="{ '--x': getX(0, getData(1)) / 2 + 'px' }">
          <div
            v-for="label in Object.keys(chartData.dataset).sort((a, b) =>
              parseInt(chartData.dataset[a][0]) >
              parseInt(chartData.dataset[b][0])
                ? -1
                : parseInt(chartData.dataset[b][0]) >
                  parseInt(chartData.dataset[a][0])
                ? 1
                : 0
            )"
            :key="label"
            class="label"
            :style="{
              width:
                (100 / Object.keys(chartData.dataset).length > 10
                  ? 100 / Object.keys(chartData.dataset).length
                  : 10) + '%'
            }"
          >
            <p>{{ label }}</p>
            <span class="tooltip">{{ label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LineChart",

  components: {},

  data() {
    return {
      sizeStyle: null,
      height: null,
      width: null
    };
  },

  props: {
    chartData: {
      type: Object,
      required: true
    },
    colors: {
      type: Array,
      required: true
    }
  },

  computed: {},

  mounted() {
    this.height = this.$refs.chart.clientHeight;
    this.width = this.$refs.chart.clientWidth;
    this.sizeStyle = {
      "--height-chart": this.height + "px",
      "--width-chart": this.width + "px"
    };
  },

  watch: {
    chartData: function() {
      this.$nextTick(() => {
        this.height = this.$refs.chart.clientHeight;
        this.width = this.$refs.chart.clientWidth;
        this.sizeStyle = {
          "--height-chart": this.height + "px",
          "--width-chart": this.width + "px"
        };
      });
      console.log("change");
      this.$forceUpdate();
    }
  },

  methods: {
    getData(index) {
      return Object.keys(this.chartData.dataset)
        .sort((a, b) =>
          parseInt(this.chartData.dataset[a][0]) >
          parseInt(this.chartData.dataset[b][0])
            ? -1
            : parseInt(this.chartData.dataset[b][0]) >
              parseInt(this.chartData.dataset[a][0])
            ? 1
            : 0
        )
        .reduce((acc, val) => {
          acc.push(this.chartData.dataset[val][index]);
          return acc;
        }, []);
    },
    getHighestPoint() {
      return 100;
    },
    getX(index, data) {
      return (this.width / data.length) * index + this.width / 2 / data.length;
    },
    getY(index, data) {
      return (data[index] / this.getHighestPoint()) * this.height;
    },
    getHypotenuse(index, data) {
      return Math.sqrt(
        (this.getY(index, data) - this.getY(index + 1, data)) ** 2 +
          (this.width / data.length) ** 2
      );
    },
    getAngle(index, data) {
      return (
        (Math.asin(
          (this.getY(index, data) - this.getY(index + 1, data)) /
            this.getHypotenuse(index, data)
        ) *
          180) /
        Math.PI
      );
    }
  }
};
</script>

<style scoped>
* {
  font-weight: var(--weight-semi-bold);
}

.container {
  height: 100%;
  width: 98%;
  display: flex;
  flex-flow: column;
}

.inner-container {
  flex-grow: 1;
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 0.3em;
}

.chart-container {
  flex-grow: 1;
  height: 72%;
  margin: auto;
}

.css-chart {
  border: 1px solid #d1d1d1;
  border-top: none;
  display: inline-block;
  height: var(--height-chart);
  padding: 0;
  position: relative;
  width: var(--width-chart);
  margin: 0;
}

.line-chart {
  list-style: none;
  margin: 0;
  padding: 0;
}

.data-point {
  background-color: white;
  border: 3.5px solid var(--color);
  border-radius: 50%;
  bottom: calc(var(--y) - 5.5px);
  height: 12px;
  left: calc(var(--x) - 5.5px);
  position: absolute;
  width: 12px;
  z-index: 1;
}

.value {
  bottom: calc(var(--y) + 9px);
  left: calc(var(--x) - 10px);
  position: absolute;
  z-index: 2;
  font-weight: var(--weight-bold);
}

.line-segment {
  background-color: var(--color);
  bottom: var(--y);
  height: 3px;
  left: var(--x);
  position: absolute;
  transform: rotate(calc(var(--angle) * 1deg));
  transform-origin: left bottom;
  width: calc(var(--hypotenuse) * 1px);
}

.yAxis {
  height: 72%;
  min-width: 4em;
  max-width: 4em;
  position: relative;
  margin: auto;
  margin-right: 1%;
}
.yAxis > div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -18%;
}

.yAxis div div {
  display: flex;
  align-items: flex-end;
  height: 20%;
}

.labels {
  display: flex;
  text-align: center;
}

.labels .label {
  padding: 0 1.5em;
  position: relative;
}

.labels .label p {
  top: 0;
  left: 0;
  width: 95%;
  cursor: help;
  min-width: 15em;
  padding: 0 1.8em;
  margin: auto;
  line-height: 120%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label .tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  color: white;
  text-align: center;
  border-radius: var(--lengthSm1);
  padding: 0.8em 1.5em;
  position: absolute;
  left: 1.1em;
  top: -3.1em;
  z-index: 2;
}

.label:hover .tooltip {
  visibility: visible;
}

.chart-lines {
  width: 100%;
  height: 100%;
  position: absolute;
}

.chart-lines div {
  width: 100%;
  height: 0.5px;
  background-color: #d1d1d191;
  position: absolute;
}

.color {
  width: 1.5em;
  height: 0.5em;
}

.legend {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 0.1em;
  margin-top: 0.1em;
}

.legend .label {
  display: flex;
  align-items: center;
  gap: 1em;
}

.legend .label div {
  font-size: 1em;
}
</style>
