var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",class:{ eNPSdisabled: !_vm.isEnpsEnabled }},[_c('h2',[_vm._v("Resultado General")]),_c('div',{ref:"chart",staticClass:"doughnut-chart__container",style:(_vm.sizeStyle)},[_c('doughnut-chart',{style:(_vm.sizeStyle),attrs:{"chartData":_vm.dataCollection,"options":{
        tooltips: { enabled: false },
        legend: {
          display: false
        },
        responsive: true,
        cutoutPercentage: 80
      }}}),_c('div',{staticClass:"ppc-percents"},[_c('div',{staticClass:"pcc-percents-wrapper"},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.value ? _vm.value : _vm.satisfactionIndexOfCompany[0]))]),_c('span',{staticClass:"percent-symbol"},[_vm._v("%")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}],staticStyle:{"font-size":"1em"}},[_vm._v(_vm._s(_vm.label))])])])],1),_c('div',{staticClass:"content"},[_c('div',[_c('h2',[_vm._v("Fortaleza")]),_c('h1',{staticClass:"primary-color"},[_vm._v(_vm._s(_vm.satisfactionIndexOfCompany[0] + "")+"%")])]),_c('div',[_c('h2',[_vm._v("Oportunidad")]),_c('h1',{staticClass:"secondary-color"},[_vm._v(" "+_vm._s(_vm.satisfactionIndexOfCompany[1] + "")+"% ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }